$(function (){
  const stickyClass = 'radio_examples--sticky';
  let headerHeight = getHeaderHeight();
  let radioExamples = $('.radio_examples');
  let radioExamplesWidth = getRadioButtonsWidth();
  let distanceToTop;
  if (radioExamples.length > 0) {
    distanceToTop = radioExamples.offset().top;

    $(window).scroll(function (){
      radioExamples.toggleClass(stickyClass, isSticky($(this).scrollTop()));

      if (radioExamples.hasClass(stickyClass)) {
        radioExamples.css({
          'top': headerHeight,
          'width': radioExamplesWidth
        });
      } else {
        radioExamples.removeAttr('style');
      }
    });

    $(window).resize(function () {
      radioExamplesWidth = getRadioButtonsWidth();
      headerHeight = getHeaderHeight();
    });
  }

  function getRadioButtonsWidth() {
    return radioExamples.outerWidth();
  }

  function getHeaderHeight() {
    return headerHeight = $('.main-navbar').height();
  }

  function isSticky(verticalScroll) {
    return verticalScroll + headerHeight > distanceToTop;
  }
});
