window.SocialHub = {
  _initialized: false,

  init: function() {
    const $socialHub = $('[rel=social-hub]');

    if (SocialHub._initialized) {
      return;
    }

    if ($socialHub.length) {
      import('./utils/album/social-hub').then(({ onClick }) => {
        $('body').delegate('[rel=social-hub]', 'click', onClick);
        SocialHub._initialized = true;
      });
    }
  },
};
