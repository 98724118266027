(function($) {
  $.fn.extend({
    autoHeight: function() {
      function autoHeight_(element) {
        return $(element)
          .css({ height: 'auto', resize: 'vertical' })
          .height(element.scrollHeight);
      }

      return this.each(function() {
        autoHeight_(this).on('input', function() {
          autoHeight_(this);
        });
      });
    },
  });

  $(document).ready(function() {
    $('textarea.vertical-auto-resize').autoHeight();
  });
})(jQuery);
