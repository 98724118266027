(function($) {
  var $secondaryNavbar = $('#secondary-navbar');
  var $secondaryMenuBlocks = $('#secondary-menu-blocks');
  var $catLinks = $secondaryNavbar.find('ul.secondary-navbar-list > li');
  var $categoryBlocks = $secondaryNavbar.find('.category-block');

  function cleanAllActiveLinks() {
    var $this = $(this);
    $catLinks.removeClass('active');
    $this.addClass('active');

    cleanAllActiveCategoryBlocks();
    activateCategoryBlock($this);
  }

  function cleanAllActiveCategoryBlocks() {
    $categoryBlocks.removeClass('active');
  }

  function activateCategoryBlock(category) {
    $categoryBlocks.removeClass('active');
    $secondaryMenuBlocks
      .find('[data-category=' + category.data('category') + ']')
      .addClass('active');
    $secondaryMenuBlocks.addClass('active');
  }

  function cleanSecondaryNavbar() {
    $('#secondary-navbar')
      .find('.active')
      .removeClass('active');
  }

  if (jQuery().hoverIntent && $catLinks.length && $secondaryNavbar.length) {
    $catLinks.hoverIntent(cleanAllActiveLinks, function() {});
    $secondaryNavbar.hoverIntent(function() {}, cleanSecondaryNavbar);
  }

  var lastScroll = 0;
  $(window).on('scroll', function() {
    var st = $(this).scrollTop();
    if (lastScroll < st) {
      // going down
      cleanSecondaryNavbar();
      if ($secondaryNavbar.hasClass('active')) {
        $secondaryNavbar.removeClass('active');
      }
    } else {
      // going up
      cleanSecondaryNavbar();
      if (!$secondaryNavbar.hasClass('active')) {
        $secondaryNavbar.addClass('active');
      }
    }
    lastScroll = st;
  });
})(jQuery);
