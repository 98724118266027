jQuery(function($) {
  if (typeof signInClick != 'function') {
    window.signInClick = function(ev) {
      var that = $(this);
      var options = {
        back: window.location.href,
        default_action: 'register',
        source_action: 'login_navbar',
      };

      var addOptionIfDataExists = function(option, prefix) {
        var option_data = that.data(option);
        var option_underscore;

        if (prefix) {
          option = prefix + option;
        }

        if (option_data) {
          option_underscore = option.replace('-', '_');
          options[option_underscore] = option_data;
        }
      };

      addOptionIfDataExists('back');
      addOptionIfDataExists('action', 'default_');
      addOptionIfDataExists('title');
      addOptionIfDataExists('source-page');
      addOptionIfDataExists('source-action');

      try {
        Login.pop(options);
        ev.preventDefault();
      } catch (e) {}
    };

    $('.user-signin').on('click', signInClick);
  }
});
