export const updateBGImage = function($ele) {
  $ele.css({
    backgroundImage: "url('" + $ele.attr('data-src') + "')",
  });
};

export const hasBGAttribute = function($ele) {
  return $ele.attr('data-bg') == 1;
};

export const hasIntersectionObserver = () => 'IntersectionObserver' in window;

const botRegex = /bot|google|baidu|bing|msn|duckduckbot|teoma|slurp|yandex/i;

export const isABot = (navigator = window.navigator) =>
  botRegex.test(navigator.userAgent);
