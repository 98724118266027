(function(w, $) {
  'location_helper' in w || (w.location_helper = {});

  w.location_helper.loadCitiesFromState = function(state_tag_id, city_tag_id) {
    var $state = $('#' + state_tag_id);
    var $city = $('#' + city_tag_id);

    $city.find('[value != ""]').remove();

    if ($state.val()) {
      $.ajax({
        url: '/p/api/location/list/' + $state.val(),
        success: function(data) {
          w.location_helper.buildCities($city, data);
        },
        dataType: 'json',
      });
    }
  };

  w.location_helper.buildCities = function(cities_elem, cities) {
    for (var city, i = 0, l = cities.length; i < l; ++i) {
      city = cities[i];
      var option = '<option value="' + city.id + '">' + city.name + '</option>';
      $(cities_elem).append(option);
    }

    if (cities.length == 1) {
      $(cities_elem).val(cities[0].id);
    }
  };
})(window, jQuery);
