$(function() {
    $('[data-toggle="kebab-toggler"]').click(function() {
      var $target = $($(this).attr('data-target'));
      toggle_menu($target);
    });

    function toggle_menu($element) {
      if ($element.hasClass('wc-kebab-menu__menu--collapsed')) {
        $element.removeClass('wc-kebab-menu__menu--collapsed');
        $element.addClass('wc-kebab-menu__menu--visible');
      } else {
        $element.addClass('wc-kebab-menu__menu--collapsed');
        $element.removeClass('wc-kebab-menu__menu--visible');
      }
    }
});
