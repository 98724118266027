var loadScript = function(path) {
  var entry = window.importScript.__db[path];

  if (entry === undefined) {
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = path;
    script.async = true;
    entry = importScript.__db[path] = {};
    entry.promise = new Promise(function(resolve, reject) {
      script.onload = resolve;
      entry.resolve = resolve;
      script.onerror = reject;
    });
    document.head.appendChild(script);
    document.head.removeChild(script);
  }

  return entry.promise;
};

function importScript(path, events) {
  return new Promise(function(resolve, reject) {
    if (events) {
      return events.forEach(function(event) {
        window.addEventListener(
          event,
          function() {
            loadScript(path)
              .then(resolve)
              .catch(reject);
          },
          { once: true }
        );
      });
    }

    return loadScript(path)
      .then(resolve)
      .catch(reject);
  });
}

importScript.__db = {};
window['importScript'] = importScript;
