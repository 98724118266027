window.rfqLanding = {
  bind(selector = '[data-type="wc-cta"]:not(.disabled)') {
    $(selector).off('click', handlerClick);
    $(selector).on('click', handlerClick);
  },
};

function handlerClick(event) {
  event.preventDefault();
  const target = $(this).attr('data-target');
  const href = $(this).attr('data-href');

  if (target && target === '_blank') {
    openNewTab(href);
  } else if (target && target.startsWith('#')) {
    // inline
    if (!isAnyFormOpen(target)) {
      trackEvent(this);
      showFormInline(target);
      getModalContent(href, target);
    }
  } else {
    go(href);
  }
  return false;
}

function getModalContent(rfqUrl, target) {
  $.get({
    url: rfqUrl,
    xhrFields: {
      withCredentials: true,
    },
    success: function(data) {
      refreshInlineFormData(data, target);
    },
  });
}

function isAnyFormOpen(target) {
  return (
    $(target).filter('#loading-placeholder').length > 0 ||
    $(target).filter('#rfq-seo-popup-content').length > 0
  );
}

function showFormInline(target) {
  $(target).html(
    '<div id="rfq-seo-popup-content">' + createLoader() + '</div>'
  );
}

function trackEvent(button) {
  var amplitudeEvent = button.getAttribute('data-amplitude');
  if (amplitudeEvent) {
    var dataAmplitude = {};
    if (IsJsonString(button.getAttribute('data-amplitude-properties'))) {
      dataAmplitude = JSON.parse(
        button.getAttribute('data-amplitude-properties')
      );
    }
    window.habTracker.track(amplitudeEvent, dataAmplitude);
  }
}

function IsJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

function refreshInlineFormData(data, target) {
  $(target).html('<div id="rfq-seo-popup-content">' + data + '</div>');
}

function createLoader() {
  return ' \
    <div id="loading-placeholder" style="min-height: 400px;"> \
      <div class="content-placeholder-geometric"> \
        <div class="blocks"> \
          <span class="element first-element"></span> \
          <span class="element second-element"></span> \
          <span class="element third-element"></span> \
        </div> \
      </div> \
    </div>';
}

$(() => {
  rfqLanding.bind();
});
