(function(window, $) {
  'use strict';

  $.formFeedback = function(options, callback, element) {
    this.element = $(element);
    this._create(options, callback);
  };

  $.formFeedback.defaults = {
    mode_text: false,
    submit_selector: ':submit',
    disabled_submit_classes: 'disabled btn-spinner',
    data_loading_text_attribute: 'loading-text',
    data_original_text_holder: 'form_feedback_submit_original_text',
    debug: false,
  };

  $.formFeedback.prototype = {
    _create: function(options) {
      this.opts = $.extend({}, $.formFeedback.defaults, options);
      this._debug('formFeedback instance created', this.element);
      this._bindEvents();
    },

    _bindEvents: function() {
      var instance = this;
      var $form = instance.element;

      this.element.submit(function() {
        instance._disableSubmit($form);
      });

      this._debug('events set');
    },

    _disableSubmit: function($form) {
      var submit_selector = this.opts.submit_selector,
        $submit_elements = $form.find(submit_selector);

      this._toggleSubmit($submit_elements, false);

      this._debug('Submit disabled');
    },

    _enableSubmit: function($form) {
      var submit_selector = this.opts.submit_selector,
        $submit_elements = $form.find(submit_selector);

      this._toggleSubmit($submit_elements, true);

      this._debug('Submit enabled');
    },

    _toggleSubmit: function($submit_elements, on) {
      this._toggleSubmitDisabledProp($submit_elements, on);

      if (this.opts.mode_text) {
        this._debug('Text mode');
        this._toggleSubmitText($submit_elements, on);
      } else {
        this._debug('Class mode');
        this._toggleSubmitClasses($submit_elements, on);
      }
    },

    _toggleSubmitDisabledProp: function($submit_elements, on) {
      $submit_elements.prop('disabled', !on);
    },

    _toggleSubmitClasses: function($submit_elements, on) {
      var disabled_class = this.opts.disabled_submit_classes;
      $submit_elements.toggleClass(disabled_class, !on);
    },

    _toggleSubmitText: function($submit_elements, on) {
      var instance = this,
        data_loading_text = this.opts.data_loading_text_attribute;

      $submit_elements.each(function() {
        var $submit_element = $(this),
          new_text;

        if (on) {
          new_text = this._retrieveOriginalText($submit_element);
        } else {
          instance._storeOriginalText($submit_element);
          new_text = $submit_element.data(data_loading_text);
        }

        if (new_text) {
          $submit_element.text(new_text);
        }
      });
    },

    _storeOriginalText: function($submit) {
      var original_text_holder = this.opts.data_original_text_holder,
        data_original_text = $submit.data(original_text_holder);

      if (data_original_text === undefined) {
        var text = $submit.text();
        $submit.data(original_text_holder, text);
      }
    },

    _retrieveOriginalText: function($submit) {
      var original_text_holder = this.opts.data_original_text_holder,
        data_original_text = $submit.data(original_text_holder);

      if (data_original_text !== undefined) {
        return data_original_text;
      }
    },

    _debug: function() {
      if (!this.opts.debug) {
        return;
      }

      if (typeof console !== 'undefined') {
        var args = Array.prototype.slice.call(arguments);
        args.push(this.state);
        /* eslint no-console: "off" */
        console.log.apply(console, args);
      }
    },

    _rcvCommand: function() {
      var command = arguments[0];
      var $form = this.element;

      switch (command) {
        case 'disable_submit':
          this._disableSubmit($form);
          break;

        case 'enable_submit':
          this._enableSubmit($form);
          break;

        default:
          this._debug('unknown command', command);
          break;
      }
    },
  };

  $.fn.formFeedback = function(options, callback) {
    var args = arguments;
    this.each(function() {
      var instance = $.data(this, 'formFeedback');
      if (instance) {
        instance._debug('instance exists');
        switch (typeof options) {
          case 'object':
            break;

          case 'string':
            instance._rcvCommand.apply(instance, args);
        }
      } else {
        instance = new $.formFeedback(options, callback, this);
        $.data(this, 'formFeedback', instance);
      }
    });

    return this;
  };
})(window, jQuery);
