const { resolve } = require('path');

const ROOT = resolve(__dirname, '..');
const COMPONENTS_PATH = resolve(
  ROOT,
  'Habitissimo',
  'Utils',
  'Web',
  'Src',
  'Component'
);
const MODULES_PATH = resolve(ROOT, 'node_modules');
const BOWER_MODULES = resolve(ROOT, 'web', 'components');
const STATIC_PATH = resolve(ROOT, 'web', 'static');
const OUTPUT_COMPONENT_CSS = resolve(STATIC_PATH, 'css', 'components');
const OUTPUT_ASSETS_BUILDED_PATH = resolve(STATIC_PATH, 'build');
const OUTPUT_COMPONENT_JS = resolve(OUTPUT_ASSETS_BUILDED_PATH, 'js');
const MAIN_BUILD_PATH = OUTPUT_ASSETS_BUILDED_PATH.replace(`${ROOT}/`, '');

module.exports = {
  ROOT,
  COMPONENTS_PATH,
  BOWER_MODULES,
  MODULES_PATH,
  OUTPUT_COMPONENT_JS,
  OUTPUT_COMPONENT_CSS,
  OUTPUT_ASSETS_BUILDED_PATH,
  MAIN_BUILD_PATH,
  STATIC_PATH,
};
