// Temp datepicker function
$.fn.datepicker = function(options = {}) {
  return new Promise((resolve, reject) => {
    // Override temp datepicker function on success
    import('bower/bootstrap-datepicker/dist/css/bootstrap-datepicker.css');
    import('./lib')
      .then(() => {
        import('../../../../../../web/js/areas/datepicker-languajes')
          .then(() => {
            const value = $.fn.datepicker.call($(this), options);
            resolve(value);
          })
          .catch(reject);
      })
      .catch(reject);
  });
};
