// Is really hard to find an example of setting
// public path for webpack at runtime
// so we need to define and access
// from global variable defined at __tail
//
// Note: on build process we could not know
// where should be put all builded js (cdn url)
//
// THIS SHOULD BE IMPORTED BY ALL ES6 MODULES

import { MAIN_BUILD_PATH } from '../../../builder/paths';
const buildOutputPath = MAIN_BUILD_PATH.replace('web/', '');

__webpack_public_path__ = `//${window.__webpack_public_path__}${buildOutputPath}/js/`;
