(function(w, $) {
  'ContentPlaceholder' in w || (w.ContentPlaceholder = {});
  var placeholder;

  w.ContentPlaceholder.getPlaceholder = function getPlaceholder(
    placeholderType
  ) {
    switch (placeholderType) {
      case 'content-placeholder-block':
        placeholder =
          '<div class="content-placeholder-block"> \
          <div class="left-column"> \
            <span class="element avatar element--circle"></span> \
          </div> \
          <div class="right-column"> \
            <div class="title-block"> \
              <span class="element text text--short"></span> \
              <span class="element text text--medium"></span> \
            </div> \
            <span class="element text text--long"></span> \
            <span class="element text text--long"></span> \
            <div class="gallery"> \
              <span class="element element--square"></span> \
              <span class="element element--square"></span> \
              <span class="element element--square"></span> \
              <span class="element element--square"></span> \
            </div> \
          </div> \
        </div>';
        break;
      case 'geometric-placeholder':
        placeholder =
          ' \
          <div class="content-placeholder-geometric"> \
            <div class="blocks"> \
              <span class="element first-element"></span> \
              <span class="element second-element"></span> \
              <span class="element third-element"></span> \
            </div> \
          </div>';
        break;
      case 'content-placeholder-job-block':
        placeholder =
          '\
          <div class="content-placeholder-job-block"> \
            <div class="left-column"> \
              <div class="title-block"> \
                <span class="element text text--medium"></span> \
              </div> \
              <div class="title-block"> \
                <span class="element text text--short"></span> \
              </div> \
              <div class="title-block"> \
                <span class="element text text--medium"></span> \
              </div> \
              <div class="gallery"> \
                <span class="element element--square"></span> \
                <span class="element element--square"></span> \
                <span class="element element--square"></span> \
              </div> \
              <div class="full-button-block"> \
                <span class="element element--button"></span> \
              </div> \
            </div> \
            <div class="right-column"> \
              <div class="title-block"> \
                <span class="element text text--short"></span> \
                <span class="element element--button"></span> \
              </div> \
            </div> \
          </div>';
        break;
      case 'content-placeholder-business-statistics':
        placeholder =
          '\
          <div class="content-placeholder-business-statistics"> \
            <span class="element element--left"></span> \
            <span class="element element--right"></span> \
            <span class="separator"></span>\
          </div>';
        break;
      case 'content-placeholder-business-preview':
        placeholder = '\
          <div class="content-placeholder-business-preview"> \
            <div class="content-placeholder-business-preview__business-header"> \
              <div class="content-placeholder-business-preview__business-logo"></div> \
              <div class="content-placeholder-business-preview__business-info"> \
                <div class="content-placeholder-business-preview__business-name"></div> \
                <div class="content-placeholder-business-preview__business-rating"></div> \
              </div> \
            </div> \
            <div class="content-placeholder-business-preview__preview-business__contents"> \
              <div class="content-placeholder-business-preview__business-description"></div> \
              <div class="content-placeholder-business-preview__business-images"></div> \
              <div class="content-placeholder-business-preview__preview-business__featured-list"> \
                <ul> \
                  <li></li> \
                  <li></li> \
                  <li></li> \
                </ul> \
              </div> \
            </div> \
            <div class="content-placeholder-business-preview__footer"> \
              <div class="content-placeholder-business-preview__business-action__button"></div> \
              <div class="content-placeholder-business-preview__business-action__button"></div> \
            </div> \
          </div> \
         ';
        break;
      default:
        placeholder = '<div>Not implemented placeholder type</div>';
        break;
    }
    return placeholder;
  };

  function _multiplyPlaceholder(count, placeholder) {
    var multipliedPlaceholder = placeholder;
    if (count > 1) {
      for (var i = 0; i < count - 1; i++) {
        multipliedPlaceholder += placeholder;
      }
    }
    return multipliedPlaceholder;
  }

  function _insertPlaceholder(formatedPlaceholder, selector) {
    var parentElement = document.querySelector(selector);
    if (parentElement) {
      parentElement.innerHTML = formatedPlaceholder;
    }
  }

  w.ContentPlaceholder.init = function(options) {
    options = $.extend(
      {
        type: 'content-placeholder-block',
        count: 1,
        selector: '.placeholder-fill',
      },
      options
    );

    var placehoderElement = w.ContentPlaceholder.getPlaceholder(options.type);
    var formatedPlaceholder = _multiplyPlaceholder(
      options.count,
      placehoderElement
    );
    _insertPlaceholder(formatedPlaceholder, options.selector);
  };
})(window, jQuery);
