'FullScreenVideo' in window || (window.FullScreenVideo = {});

window.FullScreenVideo.showVideo = function(video, videoProvider) {
  import('./FullScreenVideo').then(({ default: FullScreenVideo }) => {
    new FullScreenVideo(video, videoProvider);
  });
};

window.onYouTubePlayerAPIReady = function() {
  var player = new YT.Player('fullscreen-video', {
    events: {
      onReady: function() {
        player.playVideo();
      },
      onStateChange: function(ev) {
        if (ev.data === 0) {
          FullScreenVideo.removeVideoOverlay();
        }
      },
    },
  });
};
