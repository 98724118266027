$(document).ready(function() {
  // Due to the amount of places where this event can be fired,
  // we found more suitable to fire it from here
  $(document).on('click', '[data-amplitude="click register trigger"]', function(
    event
  ) {
    var amplitude_data = event.target.hasAttribute('data-amplitude-properties')
      ? JSON.parse(event.target.dataset.amplitudeProperties)
      : {};
    amplitude_data['URL'] = location.href;
    habTracker.track('click register trigger', amplitude_data);
  });

  // Due to the amount of places where this event can be fired,
  // we found more suitable to fire it from here
  $('[data-amplitude="click business register start"]').click(function() {
    habTracker.track('click business register start');
  });
});

// Business Register Amplitude event handlers
