var gmaps,
    MapInputWidget;

(function() {

  function defined(obj) {
    return (obj != undefined);
  }

  gmaps = {
    maps: {},
    points: [],

    createPoint: function(lat, lng) {
      return new google.maps.LatLng(lat, lng);
    },

    createMap: function(canvas, options) {
      options = options || {};
      var map_settings = $.extend(gmaps.settings.mapOptions(), options);
      var map = new google.maps.Map(document.getElementById(canvas), map_settings);
      gmaps.maps[canvas] = map;
      return map;
    },

    centerMap: function(map, points, max_zoom) {
      points = points || gmaps.points;
      var latlngbounds = new google.maps.LatLngBounds();
      for ( var i = 0; i < points.length; i++ ) {
        latlngbounds.extend( points[i] );
      }
      map.fitBounds(latlngbounds);

      if (max_zoom && map.getZoom() > max_zoom) {
        map.setZoom(max_zoom);
      }
    },

    addMarker: function(map, options) {
      options = options || {};
      options.position = options.position || map.getCenter();
      options.map = map;
      var marker_settings = $.extend(gmaps.settings.markerOptions(), options);
      gmaps.points.push(marker_settings.position);
      var marker = new google.maps.Marker(marker_settings);

      if (defined(marker_settings.info)) {
        marker.setClickable(true);
        google.maps.event.addListener(marker, 'click',
          (function(settings) {
            return function() {
              gmaps.addInfoWindow(map, {
                'position': settings.position,
                'content':  settings.info
              });
            }
          })(marker_settings)
        );
      }

      if (defined(marker_settings.url)) {
        marker.setClickable(true);
        google.maps.event.addListener(marker, 'click',
          (function(url) {
            return function() {
              window.location.href = url;
            }
          })(marker_settings.url)
        );
      }

      return marker;
    },

    addInfoWindow: function(map, options) {
      options = options || {};
      options.position = options.position || map.getCenter();
      options.map = map;
      //marker_settings = $.extend(gmaps.settings.infoWindowOptions(), options);
      gmaps.points.push(options.position);
      return new google.maps.InfoWindow(options);
    },

    addDraggableMarker: function(map, options, onDragEnd, onDrag) {
      options = options || {};
      options.draggable = true;
      var marker =  gmaps.addMarker(map, options);
      if (defined(onDrag))
      { google.maps.event.addDomListener(marker, 'drag', onDrag); }
      if (defined(onDragEnd))
      { google.maps.event.addDomListener(marker, 'dragend', onDragEnd); }
      return marker;
    },

    geocoderRequest: function(address, cb, options, cb_options) {
      options = options || {};
      options.address = address;
      var geocoder_request = $.extend(gmaps.settings.geocoderRequest(), options);

      var geocoder_callback = function(result, status) {
        if (status == google.maps.GeocoderStatus.OK) {
          cb(gmaps._enchaceGeocoderResult(result), cb_options);
        } else{
          cb([], cb_options);
        }
      }

      var geocoder = new google.maps.Geocoder();
      geocoder.geocode(geocoder_request, geocoder_callback);
    },

    showGeocoderItem: function(map, item) {
      if (defined(item.geometry.bounds)) {
        var bounds = new google.maps.LatLngBounds();
        bounds.extend(
          gmaps.createPoint(item.geometry.bounds.getNorthEast().lat(),
            item.geometry.bounds.getNorthEast().lng())
        )
        bounds.extend(
          gmaps.createPoint(item.geometry.bounds.getSouthWest().lat(),
            item.geometry.bounds.getSouthWest().lng())
        )
        map.fitBounds(bounds);
      } else {
        map.setCenter(item.position);
        if (map.getZoom() < 8 )
        { map.setZoom(8); }
      }
    },

    isApiLoaded: function() {
      try {
        google.maps.Map
        return true;
      } catch(e) {
        return false;
      }
    },

    _enchaceGeocoderResult: function(result) {
      var enchaced_result = $(result).map(function(index, item) {
        var position = gmaps.createPoint(item.geometry.location.lat(),
          item.geometry.location.lng());

        item.position = position;
        return item;
      });
      return enchaced_result;
    },

    settings: {
      mapOptions: function() {
        return {
          center: new google.maps.LatLng(Hab.config.country_lat, Hab.config.country_lng),
          dragable: true,
          keyboardShortcuts: false,
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          mapTypeControl: true,
          mapTypeControlOptions: {
            mapTypeIds: [google.maps.MapTypeId.SATELLITE, google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.HYBRID]
          },
          scaleControl: false,
          scrollwheel: false,
          zoom: Hab.config.country_map_zoom,
          zoomControl: true,
          streetViewControl: false,
          minZoom: 3
        };
      },
      markerOptions: function() {
        return {
          clickable: false,
          draggable: false,
          flat: false,
          raiseOnDrag: true,
          title: undefined,
          visible: true,
          zIndex: 1
        };
      },
      geocoderRequest: function() {
        return {
          //language:
        };
      }
    }
  }

  MapInputWidget = function(options) {
    this.configure(options || {});
    this.buildMap();
    this.buildGeocoderResultsList();
    this.setEvents();
  };

  MapInputWidget.prototype.configure = function(options) {
    this.map = undefined;
    this.marker = undefined;
    this.canvas = $(options.canvas || '#map_canvas');
    this.address = $(options.address_field || '#address');
    this.lat = $(options.lat_field || '#geo_lat');
    this.lng = $(options.lng_field || '#geo_lng');
    this.zip = $(options.zip_field || '#zip');
    this.search = $(options.search || '#geo_search');
    this.form = this.address.parents('form');
    this.events_set = false;
  }


  MapInputWidget.prototype.buildMap = function() {
    var map_options = {};
    if (this.lat.val() && this.lng.val()) {
      map_options.center = gmaps.createPoint(this.lat.val(), this.lng.val());
    }
    this.map = gmaps.createMap(this.canvas.attr('id'), map_options);
    this.marker = gmaps.addDraggableMarker(this.map, {}, function(ev) {
      this.onMapCursorMove(ev.latLng);
    }.bind(this));
  }

  MapInputWidget.prototype.buildGeocoderResultsList = function() {
    $('body').append('<div id="geocoder_results" style="display: none"><ul></ul></div>');
  }

  MapInputWidget.prototype.setEvents = function() {
    if (this.events_set) { return; }

    // Address text field
    this.address.bind('change', function() {
      this.removeEvents();
      this.zip.val('');
      gmaps.geocoderRequest(this.address.val(), this.onGeocoderSuccess.bind(this));
    }.bind(this));

    this.address.keypress(function(ev) {
      if (ev.which == 13) {          // capture enter key
        ev.preventDefault();         // prevent form submission
        this.address.trigger('change');
      }
    }.bind(this));

    // Search button
    this.search.bind('click', function() {
      this.address.trigger('change');
    }.bind(this));

    this.events_set = true;
  }

  MapInputWidget.prototype.removeEvents = function() {
    this.address.unbind('change');
    this.address.unbind('keypress');
    this.search.unbind('click');
    this.events_set = false;
  }

  MapInputWidget.prototype.onGeocoderSuccess = function(results) {
    if (results.length == 0) {
      this.setEvents();
      return;
    }

    if (results.length == 1) {
      this.focusGeocoderItem(results[0]);
      return;
    }

    $('#geocoder_results ul').empty();

    $(results).each(function(i, e) {
      var element = '<li class="result_'+i+'"><a>'+e.formatted_address+'</a></li>';
      $('#geocoder_results ul').append(element);
    });

    $.fancybox({
      'hideOnContentClick': false,
      'content': $('#geocoder_results').html(),
      'onClosed':  this.setEvents.bind(this)
    });

    // attach events on fancybox content copy
    $(results).each(function(i, result) {
      $('.fancybox-inner .result_'+i).unbind('click').bind('click', function(ev) {
        ev.preventDefault();
        this.onGeocoderResultClick(result);
        return false;
      }.bind(this));
    }.bind(this));
  }

  MapInputWidget.prototype.onMapCursorMove = function(position) {
    this.lat.val(position.lat());
    this.lng.val(position.lng());

    if (this.zip) {
      var geocoder_request = {
        'location': this.marker.position
      }
      var geocoder = new google.maps.Geocoder();
      geocoder.geocode(geocoder_request, function(results) {
        var postal = undefined;
        if (typeof results !== "undefined" && results.length) {
          postal = this.extractItemZip(results[0]);
        }
        if (postal && this.zip[0]) {
          this.zip.val(postal.long_name);
        }
      }.bind(this));
    }
  }

  MapInputWidget.prototype.onGeocoderResultClick = function(geocoder_result) {
    this.focusGeocoderItem(geocoder_result);
    $.fancybox.close();
  }

  MapInputWidget.prototype.focusGeocoderItem = function(item) {
    var street = this.extractItemStreet(item);

    if (street) {
      this.address.val(street.long_name);
    }

    gmaps.showGeocoderItem(this.map, item);
    this.marker.setPosition(item.position);
    this.onMapCursorMove(item.position);
    this.setEvents();
  }

  MapInputWidget.prototype.extractItemStreet = function(item) {
    var routes = $(item.address_components).filter(function(i, element) {
      var route = $.inArray('route', element.types) >= 0;
      var establishment =  $.inArray('establishment', element.types) >= 0;

      return (route || establishment);
    });

    return (routes[0]) ? routes[0] : undefined;
  }

  MapInputWidget.prototype.extractItemZip = function(item) {
    var postals = $(item.address_components).filter(function(i, element) {
      return $.inArray('postal_code', element.types) >= 0;
    });

    return (postals[0]) ? postals[0] : undefined;
  }

})();
