const defaultOptions = {
  once: false,
  threshold: 0,
};

const buildOptions = function(options) {
  return {
    ...defaultOptions,
    ...options,
  };
};

const observe = function(target, observer) {
  const targets = target instanceof Element ? [target] : target;

  for (let i = 0; i < targets.length; i++) {
    observer.observe(targets[i]);
  }
};

const createObserver = function(callback, options) {
  const observer = new IntersectionObserver(
    entries => {
      entries.forEach(entry => {
        const target = entry.target;
        callback(target, {
          isAboveView:
            entry.rootBounds &&
            entry.boundingClientRect.bottom < entry.rootBounds.height / 2 &&
            entry.boundingClientRect.top < 0,
          isInView: entry.isIntersecting,
          isBelowView:
            entry.rootBounds &&
            entry.boundingClientRect.top > entry.rootBounds.height / 2 &&
            entry.boundingClientRect.bottom > entry.rootBounds.height,
        });
        if (options.once) {
          observer.unobserve(target);
        }
      });
    },
    {
      threshold: options.threshold,
    }
  );
  return observer;
};

export const isInView = function(target, callback, options = {}) {
  const completeOptions = buildOptions(options);
  const observer = createObserver(callback, completeOptions);
  observe(target, observer);
};
