/**
 * Karouselo
 * @description : Create, manage and handle swype events for carousels
 */

(function(window, $) {
  'use strict';

  $.fn.karuselo = function(options) {
    options = options || {};
    var karuselo = this.carousel(options);

    new LazyHammer(this.get(0), {}).then(function(carouselHammer) {
      carouselHammer.on('swipeleft swiperight', function(e) {
        if (e.type == 'swipeleft') {
          karuselo.carousel('next');
        } else if (e.type == 'swiperight') {
          karuselo.carousel('prev');
        }
      });
    });

    return this;
  };
})(window, jQuery);
