class StepManager {
  constructor(steps, animationTime = 700) {
    this.steps = steps || [];
    this.currStepName = '';
    this.currIndexStep = -1;
    this.animationTime = animationTime;
  }

  getCurrentStepName() {
    return this.currStepName;
  }

  getCurrentStepIndex() {
    return this.currIndexStep;
  }

  setStep(stepName) {
    this.currStepName = stepName;
    this.currIndexStep = this.findIndexStep(stepName);
  }

  findIndexStep(stepName) {
    let i;
    let index = -1;

    for (i = 0; i < this.steps.length; i++) {
      if (this.steps[i] === stepName) {
        index = i;
        break;
      }
    }

    return index;
  }

  hideActiveStep() {
    $('[data-step="' + this.currStepName + '"]')
      .removeClass('active')
      .removeClass('slide-in');
  }

  showFirstStep() {
    this.showStep(this.steps[0], true);
  }

  showStep(stepName, isActiveDefault) {
    if (!stepName) {
      return;
    }

    this.setStep(stepName);

    let $step = $('[data-step="' + stepName + '"]');
    $step
      .removeClass('slide-out')
      .addClass('slide-in');

    if (isActiveDefault) {
      $step.addClass('active');
    }
  }

  goToStep(stepName) {
    setTimeout(() => {
      this.hideActiveStep();

      this.setStep(stepName);
      this.showStep(stepName);

    }, this.animationTime);
  }

  goToNextStep() {
    let indexStep = this.currIndexStep;
    indexStep += 1;
    this.goToStep(this.getStepByIndex(indexStep));
  }

  goToPreviousStep() {
    let indexStep = this.currIndexStep;
    indexStep -= 1;
    this.goToStep(this.getStepByIndex(indexStep));
  }

  getStepByIndex(index) {
    return this.steps[index];
  }

  isCurrLatestStep() {
    return this.steps.length === this.currIndexStep;
  }

  isFirstStep(step) {
    return this.findIndexStep(step) === 0;
  }
}

export default StepManager;
