(function(global) {
  'use strict';

  if (typeof global.habTracker === 'undefined') {
    global.habTracker = {};
  }

  if (typeof Leanplum === "undefined") {
    global.habTracker.leanplum = {
      trackEvent: function(eventName, context) {
        console.warn('Leanplum is not active');
        console.log('Tracking event', eventName, context);
      },

      setUserId: function(userId) {
        console.warn('Leanplum is not active');
        console.log('Setting user id', userId);
      },

      setUserAttributes: function(userAttributes) {
        console.warn('Leanplum is not active');
        console.log('Sending userAttributes to registered user', userAttributes);
      },
    };
  } else {
    global.habTracker.leanplum = {
      trackEvent: function(eventName, context) {
        Leanplum.track(eventName, context);
      },

      setUserId: function(userId) {
        Leanplum.setUserId(userId);
      },

      setUserAttributes: function(userAttributes) {
        Leanplum.setUserAttributes(userAttributes);
      },
    };
  }
})(window);
