import 'static/js/public-path';

window.Lightbox = {
  init($el, methodOrOptions = {}, onLoad = null) {
    import('../Lightbox').then(({ default: Lightbox }) => {
      import('../../_index.less');
      const lightbox = new Lightbox($el, methodOrOptions);
      if (onload === 'function') {
        onLoad(lightbox);
      }
    });
  },
};
