const SWITCHER_CLASS_ACTIVE = 'wc-switcher--active';
const SWITCHER_ITEMS_CLASS_ACTIVE = 'wc-switcher__items--active';

$(document).ready(function () {
  $('.wc-switcher').on('click', function(ev) {
    ev.preventDefault();

    const $this = $(this);
    const $link = $this.find('.wc-switcher__link');
    const isActive = $this.hasClass(SWITCHER_CLASS_ACTIVE);
    const $target = $('#' + $link.data('switcher-toggle'));

    $this.toggleClass(SWITCHER_CLASS_ACTIVE);
    $target.toggleClass(SWITCHER_ITEMS_CLASS_ACTIVE);

    if (isActive) {
      $this.closest('.wc-switcher').removeClass(SWITCHER_CLASS_ACTIVE);
      $this.closest('.wc-switcher__items').removeClass(SWITCHER_ITEMS_CLASS_ACTIVE);
    } else {
      $('.' + SWITCHER_CLASS_ACTIVE).not($this).removeClass(SWITCHER_CLASS_ACTIVE);
      $('.' + SWITCHER_ITEMS_CLASS_ACTIVE).not($target).removeClass(SWITCHER_ITEMS_CLASS_ACTIVE);
    }
  });
});
