import {
  NOTICE_ATTR_NAME,
  NOTICE_ATTR_TYPE,
  NOTICE_ATTR_ROLE,
  NOTICE_ATTR_STATE,
  NOTICE_TOAST_TYPE,
  NOTICE_ON_SHOW_EVENT,
  NOTICE_COMMUNICATION_TYPE,
  TOAST_TIME,
} from './notices-constants';

import { getAttributeFromElement, isAttributeActive } from '../../node-utils';
import { getToastTemplate } from './templates';

export function getNoticeName($notice) {
  return getAttributeFromElement($notice, NOTICE_ATTR_NAME);
}

export function getNoticeType($notice) {
  return getAttributeFromElement($notice, NOTICE_ATTR_TYPE);
}

export function getNoticeRole($notice) {
  return getAttributeFromElement($notice, NOTICE_ATTR_ROLE);
}

export function isToast($notice) {
  return getNoticeRole($notice) === NOTICE_TOAST_TYPE;
}

export function getNoticeWayToClose($notice) {
  return isToast($notice) ? 'click' : 'button';
}

export function isReady($notice) {
  return isAttributeActive($notice, NOTICE_ATTR_STATE);
}

/**
 * Listen for on  show notice event
 */
export function addListenerToNotice($notice, callback) {
  $notice.on(NOTICE_ON_SHOW_EVENT, callback);
}

function getDefaultOptions() {
  return {
    theme: 'metroui',
    animation: {
      open: 'animated fadeInRight',
      close: 'animated fadeOutRight',
    },
  };
}

function createUserOptions($notice) {
  let type = getNoticeType($notice);
  let role = getNoticeRole($notice);
  let closeWith = [getNoticeWayToClose($notice)];

  return {
    type,
    role,
    closeWith,
    text: $notice.html(),
  };
}

function setCloseableTimer(noticeInstance) {
  setTimeout(function() {
    noticeInstance && noticeInstance.close();
  }, TOAST_TIME);
}

function addCloseButton($notice) {
  const closeButton = $notice.find('[role="close-button"]').clone();
  closeButton.removeClass('hidden');
  $('.noty_close_button').html(closeButton);
}

export function createNoticeInstance($notice, opts = {}) {
  const defaultOpts = getDefaultOptions();
  const userOptions = Object.keys(opts).length
    ? opts
    : createUserOptions($notice);
  let noty = null;
  let notyOpts = {
    ...defaultOpts,
    ...userOptions,
  };

  if ($notice) {
    notyOpts = Object.assign(notyOpts, getNoticeOptions($notice, noty));
  } else {
    notyOpts = Object.assign(
      notyOpts,
      getNonDistpacherTemplateOptions($notice, noty)
    );
  }

  if (notyOpts.role === NOTICE_TOAST_TYPE) {
    notyOpts.timeout = TOAST_TIME;
  }

  return import('@/noty').then(({ default: Noty }) => {
    import('../index.less');

    Noty.setMaxVisible(1);

    noty = new Noty(notyOpts);

    if (!$notice) {
      setCloseableTimer(noty);
    }

    return noty;
  });
}

function onShowNotice($notice, noty) {
  if (getNoticeRole($notice) === NOTICE_COMMUNICATION_TYPE) {
    addCloseButton($notice);
    $('.noty_layout').attr('style', 'width: 400px !important');
  } else {
    setCloseableTimer(noty);
    $('.noty_layout').attr('style', 'width: 300px !important');
  }
}

function getNoticeOptions($notice, noty) {
  return {
    callbacks: {
      onShow: () => {
        onShowNotice($notice, noty);
      },
    },
  };
}

function getNonDistpacherTemplateOptions(options) {
  return {
    callbacks: {
      onTemplate: function() {
        this.barDom.innerHTML = getToastTemplate(this.options);
      },
    },
  };
}
