$(document).ready(function() {
  const $collapseTrigger = $('[data-toggle="collapse"]');

  $collapseTrigger.on('click', function(ev) {
    const collapseID = $(this).attr('data-target');
    const $collapse = $(collapseID);

    $collapse.on('show.bs.collapse', function() {
      $(ev.target)
        .find('.collapse-icon')
        .addClass('icon--opened')
        .removeClass('icon--closed');
    });

    $collapse.on('hide.bs.collapse', function() {
      $(ev.target)
        .find('.collapse-icon')
        .addClass('icon--closed')
        .removeClass('icon--opened');
    });
  });
});
