$(document).ready(function() {
  const rootClass = 'complex_radio_input';
  const $root = $(`.${rootClass}`);
  const $input = $root.find('input[type="radio"]');
  const selectedClass = `${rootClass}--selected`;

  $root.click((event) => {
    $(event.target)
      .closest($root)
      .find($input)
      .prop('checked', true)
      .trigger('change');

    changeCheckedHandler(event);
  });

  function changeCheckedHandler(event) {
    const name = $(event.target)
      .closest($root)
      .find($input)
      .attr('name');

    $(`input[name="${name}"]`)
      .closest($root)
      .removeClass(selectedClass);

    $(event.target)
      .closest($root)
      .addClass(selectedClass);
  }
});
