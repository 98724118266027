(function(w, $) {
  var ENDPOINT = '/p/api/autocomplete/category',
    INIT_ENDPOINT = '/p/api/category/list',
    WILDCARD = '%QUERY',
    BASE_OPTIONS = {
      extraOption: {},
      alwaysSelectOne: true,
      delay: 150,
      hint: true,
      minLength: 0,
      autoselect: true,
      highlight: true,
      limit: 200,
      tree_level: [1, 2], // Subs and subsubs
    };

  $.fn.categorySearcher = function(options, value) {
    var $input = this,
      $hiddenInput = getOrCreateHiddenInputKey($input);
    var settings, engine;

    switch (options) {
      case 'val':
        return doValAction($input, value);
      case 'keyVal':
        return doKeyValAction($hiddenInput, value);
      case 'isValid':
        return isValid($input, $hiddenInput);
    }

    options = options || {};
    settings = buildSettings(options);

    engine = initializeBloodhound($input, settings);
    initializeTypeahead($input, engine, settings);

    bindHiddenInputKey($input, settings, $hiddenInput);
    bindEnvents($input, settings, $hiddenInput);
  };

  function doValAction($input, value) {
    if (value !== undefined) {
      return $input.typeahead('val', value);
    }

    return $input.typeahead('val');
  }

  function doKeyValAction($hiddenInput, value) {
    if (value !== undefined) {
      return $hiddenInput.val(value);
    }

    return $hiddenInput.val();
  }

  function buildSettings(options) {
    var defaultSettings = {
      id_key: 'id',
      display_key: 'name',
      source: ENDPOINT + '?search=' + WILDCARD,
      request: prepareRequest,
      response: prepareResponse,
      suggestionTemplate: isMobile()
        ? suggestionTemplate
        : suggestionTemplateDesktop,
    };

    return $.extend(defaultSettings, BASE_OPTIONS, options);
  }

  function getOrCreateHiddenInputKey($input) {
    /**
     * @todo Improve way to know wether its our own custom element
     */
    var id = $input.attr('id');
    var $hiddenInput = $('[name="' + id + '"]');

    if ($hiddenInput.length) {
      return $hiddenInput;
    }

    $hiddenInput = $('<input>', {
      type: 'hidden',
      name: id,
    });

    $input.after($hiddenInput);

    return $hiddenInput;
  }

  function initializeBloodhound($input, settings) {
    var engine = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.whitespace(settings.display_key),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: settings.source,
        wildcard: WILDCARD,
        prepare: function(query, request) {
          return settings.request(query, request, $input, settings);
        },
        transform: function(response) {
          return settings.response(response, $input, settings);
        },
      },
      rateLimitBy: settings.delay,
      dupDetector: function(remoteMatch, localMatch) {
        return remoteMatch[settings.id_key] === localMatch[settings.id_key];
      },
    });

    engine.initialize();

    return engine;
  }

  function initializeTypeahead($input, engine, settings) {
    var initialData = {
      highlight: settings.highlight,
      minLength: settings.minLength,
      hint: settings.hint,
      autoselect: settings.autoselect,
    };

    $input.typeahead(initialData, {
      valueKey: settings.id_key,
      display: settings.display_key,
      limit: settings.limit,
      source: engine,
      templates: {
        suggestion: settings.suggestionTemplate,
        notFound: notFoundTemplate,
      },
    });
  }

  function bindHiddenInputKey($input, settings, $hiddenInput) {
    $input.bind('typeahead:select typeahead:autocompleted', function(
      _,
      selection
    ) {
      $hiddenInput.val(selection[settings.id_key]);
      $input.trigger('categorySearcher:autocompleted', selection);
    });

    $input.on('input', function() {
      $hiddenInput.val('');
    });
  }

  function bindEnvents($input, settings, $hiddenInput) {
    $input.bind('typeahead:select', function(_, selection) {
      $input.trigger('categorySearcher:select', selection);
      if (selection.id === -1 && extraOptionHasRedirection(settings)) {
        w.open(settings.extraOption.redirectUrl, settings.extraOption.target);
      }
    });

    $input.bind('typeahead:asyncreceive', function() {
      $input
        .siblings('.tt-menu')
        .find('.tt-dataset > .tt-suggestion')
        .first()
        .addClass('tt-cursor');
    });

    $input.bind('typeahead:cursorchange', function() {
      $input
        .siblings('.tt-menu')
        .find('.tt-dataset > .tt-suggestion')
        .first()
        .removeClass('tt-cursor');
    });

    $input.bind('change', function() {
      if (settings.alwaysSelectOne) {
        var firstElementOfRequest = $input.prop('firstElement');
        var canAutocompletFirstElement =
          !isEmpty($input) &&
          !isValid($input, $hiddenInput) &&
          firstElementOfRequest;

        if (canAutocompletFirstElement) {
          $input.typeahead('val', firstElementOfRequest.name);
          $hiddenInput.val(firstElementOfRequest.id);

          $input.trigger('categorySearcher:select', firstElementOfRequest);
        }
      }

      if (hasError($input, $hiddenInput)) {
        $input.typeahead('val', '');
        $input.typeahead('keyVal', []);
        $input.trigger('categorySearcher:error');
      }

      if (isEmpty($input)) {
        $input.typeahead('val', '');
        $input.typeahead('keyVal', []);
        $input.trigger('categorySearcher:empty');
      }
    });
  }

  function prepareRequest(query, request, $input, settings) {
    var params = { limit: settings.limit };

    $input.prop('hasSearchedSomething', query.length > 0);

    if (query.length === 0) {
      request.url = INIT_ENDPOINT;
      params.tree_level = 1; // Subcategories
      request.url += '?';
    } else {
      request.url = request.url.replace(WILDCARD, encodeURIComponent(query));
      params.tree_level = settings.tree_level;
      request.url += '&';
    }

    request.url += $.param(params);

    return request;
  }

  function prepareResponse(response, $input, settings) {
    if (response.length > 0 && !firstElementIsExtraOption(response)) {
      $input.prop('firstElement', response[0]);
    } else {
      $input.prop('firstElement', null);
    }

    if (canAddExtraOption($input, response, settings)) {
      response = addExtraOption(response, settings.extraOption);
    }

    return response;
  }

  function addExtraOption(response, extraOption) {
    response.push({ id: extraOption.id, name: extraOption.name });
    return response;
  }

  function suggestionTemplate(value) {
    return $('<span>', { text: value.name });
  }

  function suggestionTemplateDesktop(value) {
    var template = $('<span>', { text: value.name });
    if (value.parent_name) {
      var gray_text = __('en') + ' ' + value.parent_name;
      template.append(
        ' ',
        $('<small>', { text: gray_text }).addClass('text--gray')
      );
    }

    return template;
  }

  function notFoundTemplate(searched) {
    if (!searched.query) return;

    var template = $('<span>', {
      text: __('No se han encontrado resultados'),
    });
    template.addClass('tt-suggestion tt-not-found');

    return template;
  }

  function isValid($input, $hiddenInput) {
    return !isEmpty($input) && !hasError($input, $hiddenInput);
  }

  function hasError($input, $hiddenInput) {
    return $input.val() && !$hiddenInput.val();
  }

  function isEmpty($input) {
    return $input.val().trim() === '';
  }

  function canAddExtraOption($input, response, settings) {
    return (
      $input.prop('hasSearchedSomething') &&
      hasExtraOption(settings) &&
      !firstElementIsExtraOption(response) &&
      !lastElementIsExtraOption(response, settings)
    );
  }

  function firstElementIsExtraOption(response) {
    return response.length > 0 && 'id' in response[0] && response[0].id === -1;
  }

  function lastElementIsExtraOption(response, settings) {
    var extraOptions = settings.extraOption;
    var lastItem = response[response.length - 1];

    if (!extraOptions.hasOwnProperty('id')) return false;
    if (!lastItem) return false;

    return lastItem.id === extraOptions.id;
  }

  function hasExtraOption(settings) {
    return 'extraOption' in settings && 'name' in settings.extraOption;
  }

  function extraOptionHasRedirection(settings) {
    return hasExtraOption(settings) && 'redirectUrl' in settings.extraOption;
  }
})(window, jQuery);
