const ERROR_TYPE_ICON = 'close-circle-o';
const INFO_TYPE_ICON = 'comments-o';
const WARNING_TYPE_ICON = 'warning-o';
const SUCCESS_TYPE_ICON = 'check-circle-o';

const ERROR_TYPE = 'error';
const INFO_TYPE = 'info';
const WARNING_TYPE = 'warning';
const SUCCESS_TYPE = 'success';

export const getToastTemplate = options => {
  let iconType = getIconTypeByToastType(options.type);

  return `
    <section role="notice" data-notice-role="toast" class="noty_body">
      <article role="notice-icon" class="toast__icon toast__icon--xs">
        <div class="icon-circle-container icon-circle-${options.type}">
          <i class="icon icon-${iconType}"></i>
        </div>
      </article>
      <article role="notice-content" class="toast__content">
        <p>
          ${options.text}
        </p>
      </article>
    </section>
  `;
};

function getIconTypeByToastType(type) {
  let iconType = '';

  switch (type) {
    case ERROR_TYPE:
      iconType = ERROR_TYPE_ICON;

      break;
    case INFO_TYPE:
      iconType = INFO_TYPE_ICON;

      break;
    case WARNING_TYPE:
      iconType = WARNING_TYPE_ICON;

      break;
    case SUCCESS_TYPE:
      iconType = SUCCESS_TYPE_ICON;

      break;
  }

  return iconType;
}
