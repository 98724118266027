(function(w) {
  'use strict';

  w.rfq = {
    subcategorySubSubcategory: function(select1, select2, initial1, initial2) {
      initial2 = initial2 != undefined ? initial2 : '';
      var select = $('select[name="' + select1 + '"]');

      if (initial1 != undefined && initial1 != '') {
        this._loadSubSubCategories(initial1, select2, initial2);
      }

      select.bind(
        'change',
        function(ev) {
          this._loadSubSubCategories(ev.target.value, select2, '');
        }.bind(this)
      );
    },

    _loadSubSubCategories: function(parent, element, value) {
      var select = this._setSelectLoading(element);
      value = value ? value : '""';

      $.getJSON(
        '/do_ajax/autocomplete_subsubcategories?subcategory=' + parent,
        function(data) {
          select.empty();
          $.each(data.subsubcategories, function(i, e) {
            select[0].options[select[0].options.length] = new Option(
              e.name,
              e.id
            );
          });
          select.children('option[value=' + value + ']')[0].selected = true;
          if (select.attr('size')) {
            select.attr('size', data.subsubcategories.length);
          }
          if (select[0].options.length == 1) {
            select.empty();
            select[0].options[select[0].options.length] = new Option(
              __('Todas'),
              0
            );
            $('#subsubcategory_selector').hide();
          } else {
            $('#subsubcategory_selector').show();
            select.removeAttr('disabled');
          }
          select.trigger('options_changed');
        }
      );
    },

    _setSelectLoading: function(element) {
      var select = $('select[name="' + element + '"]')
        .empty()
        .attr('disabled', true);
      select[0].options[select[0].options.length] = new Option(
        __('Cargando...'),
        ''
      );
      return select;
    },

    stateCity: function(select1, select2, initial1, initial2) {
      initial2 = initial2 != undefined ? initial2 : '';
      var state = $('select[name="' + select1 + '"]');
      var city = $('select[name="' + select2 + '"]');

      // Preselect options if initial value is defined
      if (
        initial1 != undefined &&
        initial1 != '' &&
        state.children("option[value='" + initial1 + "']")[0] != undefined
      ) {
        state.children('option[value=' + initial1 + ']')[0].selected = true;
        this._loadCities(initial1, select2, initial2);
      }

      // Sometimes the browser will remember a choice and will load the select
      // with an option selected, but it will not trigger the onChange event
      if (initial1 == undefined && state.val() != '' && city.val() == '') {
        this._loadCities(state.val(), select2, initial2);
      }

      state.bind(
        'change',
        function(ev) {
          this._loadCities(ev.target.value, select2, '');
        }.bind(this)
      );
    },

    _loadCities: function(parent, element, value) {
      var select = this._setSelectLoading(element);
      value = value ? value : '""';

      $.getJSON(
        '/do_ajax/autocomplete_cities',
        { use_key: 'id', state: parent },
        function(data) {
          select.empty();
          $.each(data.cities, function(i, e) {
            select[0].options[select[0].options.length] = new Option(
              e.name,
              e.id
            );
          });
          var city_selected = select.children('option[value=' + value + ']')[0];
          select.removeAttr('disabled');
          if (city_selected) {
            city_selected.selected = true;
          }
          select.trigger('options_changed');
        }
      );
    },
  };
})(window);
