import {
  getNoticeName,
  isReady,
  addListenerToNotice,
  createNoticeInstance,
} from './notices-utils';

const $notices = $('[role="notice"]');

$notices.each(function() {
  let $noticeElem = $(this);
  addListenerToNotice($noticeElem, function() {
    createNoticeInstance($noticeElem).then(noty => {
      noty.show();
    });
  });

  $(document).ready(function() {
    if (isReady($noticeElem)) {
      Utilities.showNoticeByName(getNoticeName($noticeElem));
    }
  });
});
