$(document).ready(function() {
  let $navbar = $('[role="fixed"]');

  if ($navbar.length > 0) {
    let topMargin = getTopMargin();

    $(window).scroll(function() {
      toggleSticky(topMargin);
    });

    $(window).resize(function() {
      $navbar = $('[role="fixed"]');
      topMargin = getTopMargin();
    });
  }

  function toggleSticky(topMargin) {
    if (window.pageYOffset >= topMargin) {
      $navbar.addClass('tabs--sticky');
    } else {
      $navbar.removeClass('tabs--sticky');
    }
  }

  function getTopMargin() {
    return $navbar[0].getBoundingClientRect().top + window.scrollY;
  }
});
