$(function() {

  function handleStateChange($checkboxWrapper, $input) {
    $input.prop('checked', !$input.is(':checked'));
    if ($input.is(':checked')) {
      $checkboxWrapper.find('.icon').addClass('icon-checkbox-on');
      $checkboxWrapper.find('.icon').removeClass('icon-checkbox-off');
    } else {
      $checkboxWrapper.find('.icon').addClass('icon-checkbox-off');
      $checkboxWrapper.find('.icon').removeClass('icon-checkbox-on');
    }
  }

  $('.wc-checkbox').click(function() {
    const $input = $(this).find('input');

    handleStateChange($(this), $input);

    Utilities.EventBus.emit('wc-checkbox:change', {
      target: $input,
    });

  });

  Utilities.EventBus.subscribe('wc-checkbox:toggle', function(data) {
    const $input = data.target;
    handleStateChange($input.parent(), $input);
  });
});
