(function (w, $) {
  $.fn.LocationSearcher = function (locationInput) {
    var $locationInput = locationInput || 'location-id';
    var $input = this;
    var ENDPOINT = '/p/api/autocomplete/location';
    var onSelect = function(selection) {
      $input.trigger('locationSearcher:select', selection);
    };
    var onError = function(type) {
      if (type === 'INVALID') {
        $input.trigger('locationSearcher:invalid');
      }
    };

    var BASE_OPTIONS = {
      extraOption: {},
      always_select_one: true,
      delay: 150,
      hint: true,
      minLength: 0,
      autoselect: true,
      highlight: true,
      limit: 200, // cambiar,
      on_error: onError,
      on_select: onSelect,
    };

    var locationConfig = $.extend({}, BASE_OPTIONS, {
      source: ENDPOINT,
      input: '[name=' + $locationInput + ']',
      suggestion_template: function (value) {
        var template = $('<span>', {
          text: value.name,
        });

        if (value.hint) {
          template.append(
            ' ',
            $('<small>', {
              text: value.hint,
            })
          );
        }

        return template;
      },
      prepare_request: function(query, settings) {
        if (query.length > 1) {
          settings.url = settings.url.replace('%QUERY', query) + '&';
          var urlParams = {
            tree_level: [2],
            limit: 50,
          };

          settings.url += $.param(urlParams);
        }

        return settings;
      },
      templates: {
        notFound: function(searched) {
          if (!searched.query || searched.query.length < 2) {
            return;
          }

          var template = $('<span>', {
            text: __('No se han encontrado resultados'),
          });
          template.addClass('tt-suggestion tt-not-found');

          return template;
        },
        pending: function(searched) {
          if (searched.query === '') {
            return (
              "<span class='tt-suggestion tt-selectable' role='geolocation-anchor' onclick='next_to_me()'><i class='icon icon-map-marker-o'></i> " +
              __('Cerca de mí') +
              '</span>'
            );
          }
          return;
        },
      },
    });

    typeaheadAutocomplete($(this), locationConfig);

    $input.bind('typeahead:select', function(_, selection) {
      $input.trigger('locationSearcher:select', selection);
    });
  };
})(window, jQuery);
