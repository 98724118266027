jQuery(function($) {
  window.LANG_CODE = $('meta[http-equiv="content-language"]').attr('content');

  if ($.validator) {
    $.extend($.validator, {
      messages: {
        required: __('Este campo es obligatorio'),
        email: __('Introduce una dirección válida'),
        url: __('Introduce una URL válida'),
        date: __('Introduce una fecha válida'),
        dateISO: __('Introduce una fecha ISO válida'),
        number: __('Introduce un número válido'),
        digits: __('Solo se permiten dígitos'),
        creditcard: __('Introduce un número de tarjeta válida'),
        maxlength: $.validator.format(
          __('No introduzcas más de {0} caracteres.')
        ),
        minlength: $.validator.format(__('Introduce al menos {0} caracteres')),
      },
    });
  }
});
