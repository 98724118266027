$(function($) {
  var isRequestPending = false;
  var bootboxArgRegex = /^bootbox/;

  function getBootboxArgs($element) {
    var data = $element.data();
    var bootboxArgs = {};

    Object.keys(data)
      .filter(function(dataKey) {
        return bootboxArgRegex.test(dataKey);
      })
      .forEach(function(validDataKey) {
        var arg = {};
        var newKey = validDataKey.replace(bootboxArgRegex, '');
        if (newKey.length > 0) {
          newKey = newKey[0].toLowerCase() + newKey.substr(1);
        }

        arg[newKey] = data[validDataKey];
        Object.assign(bootboxArgs, arg);
      });

    return bootboxArgs;
  }

  function openModal(event) {
    var $myself = $(event.currentTarget);
    var url = $myself.attr('data-href') || $myself.attr('href');
    var trackEventSection = $myself.attr('data-trackevent-section') || '';
    $myself.attr('disabled', true);

    $.ajax({
      url: url,
      data: {
        section: trackEventSection,
      },
    })
      .done(function(data) {
        var html = data.html || data.content || data;
        bootbox.dialog(
          Object.assign(
            {
              message: html,
            },
            getBootboxArgs($myself)
          )
        );
      })
      .fail(function() {
        // pass
      })
      .always(function() {
        $myself.attr('disabled', false);
        isRequestPending = false;
      });
  }

  $('.open-in-modal').on('click', function(event) {
    event.preventDefault();

    if (!isRequestPending) {
      isRequestPending = true;
      openModal(event);
    }
  });
});
