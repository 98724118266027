$(document).ready(() => {
  var $sideMenu = $('.wc-side-menu');
  var $backdrop = $('.wc-side-menu__backdrop');

  $('[data-role=side-menu-toggle]').on('click', openSideMenu);

  $($backdrop).on('touchstart mousedown tap', closeSideMenu);
  $($sideMenu.find('a')).on('click', closeSideMenu);

  function openSideMenu() {
    $sideMenu.attr('aria-hidden', false);
    $('body').addClass('side-menu-open');
  }

  function closeSideMenu() {
    $sideMenu.attr('aria-hidden', true);
    $('body').removeClass('side-menu-open');
  }
});
