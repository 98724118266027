jQuery(function($) {
  $('#footer .login').on('click', function(ev) {
    try {
      Login.pop({
        default_action: 'login',
      });
      ev.preventDefault();
      /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
    } catch (e) {}
  });

  var xs_max_width = 767;
  var is_xs = false;

  var collapse = function($section) {
    var $ul = $section
      .find('ul')
      .addClass('dropdown-menu')
      .attr('role', 'menu');
    var $titleTag = $section.find('span.title').remove();
    var $toggle = $('<a data-toggle="dropdown">').text($titleTag.text());

    $section
      .addClass('dropdown')
      .removeClass('links')
      .append($toggle, $ul);
  };

  var uncollapse = function($section) {
    var $ul = $section
      .find('ul.dropdown-menu')
      .removeClass('dropdown-menu')
      .removeAttr('role');
    var $toggle = $section.find('a[data-toggle="dropdown"]').remove();
    var $titleTag = $('<span>')
      .addClass('title')
      .text($toggle.text());

    $section
      .removeClass('dropdown')
      .addClass('links')
      .append($titleTag, $ul);
  };

  $(window).on('load resize', function() {
    if (is_xs !== (is_xs = $(this).width() <= xs_max_width)) {
      $('#footer .section.collapsible').each(function(i, section) {
        var $section = $(section);

        if (is_xs) {
          collapse($section);
        } else {
          uncollapse($section);
        }
      });
    }
  });
});
