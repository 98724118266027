(function(global) {
  'use strict';

  global.__ = function(string, replacements) {
    var text = string;

    if (typeof hab_i18n != 'undefined') {
      try {
        if (typeof hab_i18n[string] !== 'undefined' && hab_i18n[string]) {
          text = hab_i18n[string];
        }
      } catch (e) {}
    }

    if (replacements !== undefined) {
      text = nano(text, replacements);
    }

    return text;
  };

  jQuery.defined = function(obj) {
    return obj != undefined;
  };

  global.trunk8 = function(selector, options) {
    var opts;
    var more_text = __('ver más');
    var less_text = __('ver menos');
    switch (typeof options) {
      case 'object':
        opts = options;
        if (opts.less_text) {
          less_text = opts.less_text;
        }
        if (opts.less_text) {
          more_text = opts.more_text;
        }
        break;

      case 'number':
        opts = {
          lines: options,
        };
        break;

      default:
        opts = {};
        break;
    }

    opts.tooltip = false;
    opts.fill = '&hellip; <a class="trunk8-more" href="#">{more}</a>';
    opts.fill = nano(opts.fill, {
      more: more_text,
    });

    //console.log(opts);

    jQuery(selector).trunk8(opts);
    //return;

    //console.log("events");

    jQuery(selector).delegate('.trunk8-more', 'click', function() {
      var less = ' <a class="trunk8-less" href="#">{less}</a>';
      less = nano(less, {
        less: less_text,
      });
      jQuery(this)
        .closest(selector)
        .trunk8('revert')
        .append(less);
      return false;
    });

    jQuery(selector).delegate('.trunk8-less', 'click', function() {
      jQuery(this)
        .closest(selector)
        .trunk8();
      return false;
    });
  };

  global.trunk8html = function(selector, options) {
    return trunk8(
      selector,
      $.extend(
        {
          parseHTML: true,
        },
        options
      )
    );
  };

  global.trunk_overflow = function(selector, more, height, animate, classes) {
    animate = animate || 'slow';
    classes = classes || '';
    $(selector).each(function() {
      var $element = $(this);
      $element.css({
        maxHeight: height,
        overflow: 'hidden',
      });
      if ($element.height() < $element.prop('scrollHeight')) {
        var link_more = '<div class="show-more">';
        link_more +=
          '<a class="show-more-link {classes}" title="{more}" href="#">{more}</a>';
        link_more += '</div>';
        link_more = nano(link_more, {
          more: more,
          classes: classes,
        });

        $element.append(link_more);
        $element.css('height', $element.height());
        $element.addClass('overflowed');
      }

      $element.find('.show-more-link').bind('click', function(event) {
        $element.css('max-height', '100%');
        $element.css('height', 'auto');
        $element.find('.show-more').hide();
        event.preventDefault();
      });
    });
  };

  global.trunkOverflow = function(selector, options) {
    function hideElement($element, height) {
      $element.css({
        maxHeight: height,
        overflow: 'hidden',
        position: 'relative',
      });
    }

    function addTruncateActions($element, options) {
      $element.append(options.more.action);
      if (options.less && Object.keys(options.less).length) {
        options.less.action.addClass('hidden');
        $element.append(options.less.action);
      }

      $element.css('height', $element.height());
      $element.addClass('overflowed');
    }

    function moreActionHandler(event) {
      event.preventDefault();

      var $element = event.data.$element;
      var options = event.data.options;

      $element.css('max-height', '100%');
      $element.css('height', 'auto');

      $element.find(options.more.action).toggleClass('hidden');
      if (options.less && Object.keys(options.less).length) {
        $element.find(options.less.action).toggleClass('hidden');
      }
    }

    function lessActionHandler(event) {
      event.preventDefault();

      var $element = event.data.$element;
      var options = event.data.options;

      $element.find(options.less.action).toggleClass('hidden');
      $element.find(options.more.action).toggleClass('hidden');

      $element.css({
        maxHeight: options.height,
      });
    }

    var $moreAction = $('<div>', {
      class: 'show-more',
    }).append(
      $('<a>', {
        text: __('Leer más'),
        class: 'show-more-link raquo',
        href: '#',
      })
    );
    var defaultOptions = {
      more: {
        action: $moreAction,
        triggerSelector: '.show-more-link',
      },
      less: undefined,
      height: '100px',
      animate: 'slow',
    };

    if (options.showLess) {
      defaultOptions.less = {
        action: $('<div>', {
          class: 'show-less',
        }).append(
          $('<a>', {
            text: __('Leer menos'),
            class: 'show-less-link link-secondary raquo',
            href: '#',
          })
        ),
        triggerSelector: '.show-less-link',
      };
    }

    // Object.assign does not provide deep copy and I don't want to implment it.
    // So I decided to do only the two deeps copies I need manually.
    options.more = Object.assign({}, defaultOptions.more, options.more);
    options.less = Object.assign({}, defaultOptions.less, options.less);

    options = Object.assign(defaultOptions, options);

    $(selector).each(function(_, element) {
      var $element = $(element);
      hideElement($element, options.height);

      if ($element.height() < $element.prop('scrollHeight')) {
        addTruncateActions($element, options);
      }

      $element.find(options.more.triggerSelector).bind(
        'click',
        {
          $element: $element,
          options: options,
        },
        moreActionHandler
      );

      if (options.less) {
        $element.find(options.less.triggerSelector).bind(
          'click',
          {
            $element: $element,
            options: options,
          },
          lessActionHandler
        );
      }
    });
  };

  global.scrollToAnimate = function(element, duration, offset, complete) {
    offset = offset || 0;
    offset += $(element).offset().top;
    duration = duration || 400;
    $('html, body')
      .stop()
      .animate(
        {
          scrollTop: offset,
        },
        duration,
        undefined,
        complete
      );
  };

  global.typeaheadAutocomplete = function(element, settings) {
    // Default settings
    var defaultsettings = {
      source: '',
      prepare_request: null,
      prepare_response: null,
      highlight: true,
      display_key: 'name',
      id_key: 'id',
      input: '#service',
      no_selected_error: '',
      autoselect: false,
      hint: true,
      limit: 120,
      delay: 1000,
      error_class: 'has-error',
      open_default: false,
      always_select_one: true,
      select_on_submit: true,
      on_select: null,
      minLength: 0,
      suggestion_template: null,
    };

    settings = $.extend(defaultsettings, settings || {});

    var $element = $(element),
      $input = $(settings.input);

    settings.remote = {
      url: settings.source + '?search=%QUERY',
      wildcard: '%QUERY',
    };

    if (settings.prepare_request) {
      settings.remote.prepare = settings.prepare_request;
    }

    if (settings.prepare_response) {
      settings.remote.transform = settings.prepare_response;
    }

    // Configure data source with bloodhound
    var data = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace(
        settings.display_key
      ),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: settings.remote,
      rateLimitBy: settings.delay,
      dupDetector: function(remoteMatch, localMatch) {
        return remoteMatch[settings.id_key] === localMatch[settings.id_key];
      },
    });
    data.initialize();

    // Configure typeahead
    var initialData = {
      highlight: settings.highlight,
      minLength: settings.minLength,
      hint: settings.hint,
      autoselect: settings.autoselect,
    };

    var typeaheadConfig = {
      displayKey: settings.display_key,
      valueKey: settings.id_key,
      limit: settings.limit,
      source: data,
    };

    if (settings.suggestion_template) {
      typeaheadConfig.templates = {};
      typeaheadConfig.templates.suggestion = settings.suggestion_template;
    }

    if (settings.templates) {
      typeaheadConfig.templates = $.extend(
        {},
        typeaheadConfig.templates || {},
        settings.templates
      );
    }

    $element
      .typeahead(initialData, typeaheadConfig)
      .bind('typeahead:selected', function(obj, datum) {
        $element.removeClass(settings.error_class);
        $input.val(datum[settings.id_key]);

        if (settings.on_select) {
          settings.on_select(datum);
        }
      })
      .on('typeahead:autocompleted', function(e, datum) {
        $element.removeClass(settings.error_class);
        $input.val(datum[settings.id_key]);

        if (settings.on_select) {
          settings.on_select(datum);
        }
      });

    if (settings.always_select_one) {
      $element.on('change', function() {
        var value = $(this).val();
        if ($input.val() == '' && value != '') {
          var result = data.search(value, '', function(res) {
            // Select one of the resuls if the user dont use autocomplete
            if (res.length > 0) {
              $element.removeClass(settings.error_class);
              $input.val(res[0][settings.id_key]);
              $element.typeahead('val', res[0][settings.display_key]);
              if (settings.on_select) {
                settings.on_select(res[0]);
              }
              if (settings.on_error) {
                settings.on_error('EMPTY');
              }
            } else {
              $element.typeahead('val', '');
              $input.val('');
              if (settings.on_error) {
                settings.on_error('INVALID');
              }
            }
            // Alert if not match with the reults
            if (settings.error_class) {
              $element.addClass(settings.error_class);
            }
          });

          if (
            Object.prototype.toString.call(result) === '[object Array]' &&
            !result.length
          ) {
            $element.addClass(settings.error_class);
          }
        } else {
          $element.removeClass(settings.error_class);
        }
      });
    }

    // Open default values
    if (settings.open_default) {
      $element.on('focus', function() {
        if ($(this).val() == '') {
          $('.tt-hint').hide();
        }
        var ev = $.Event('keydown');
        ev.keyCode = ev.which = 40;
        $(this).trigger(ev);
        return true;
      });
    }

    // Reset input value if the input change
    if (element != settings.input) {
      $element.on('input', function() {
        $input.val('');
      });
    }

    //Hide hint if not value
    $element.on('keyup', function(e) {
      e = e || window.event;

      if (e.keyCode != 38 && e.keyCode != 40) {
        $element.siblings('.tt-menu').scrollTop(0);
      }

      if ($element.val() == '') {
        $('.tt-hint').hide();
      } else {
        $('.tt-hint').show();
      }
    });

    //Select first if enter pressed, if value submit form
    if (settings.select_on_submit) {
      $element.on('keypress', function(e) {
        e = e || window.event;
        if (e.keyCode == 13) {
          var value = $(this).val();
          if ($input.val() == '' && value != '' && value.length > 2) {
            data.get(value, function(res) {
              if (res.length > 0) {
                $element.removeClass(settings.error_class);
                $input.val(res[0][settings.id_key]);
                if (settings.on_select) {
                  settings.on_select(res[0]);
                }
              }
              if (settings.error_class && $input.val() == '') {
                $element.addClass(settings.error_class);
              } else {
                $element.parents('form:first').submit();
              }
            });
            e.preventDefault();
          } else {
            $element.removeClass(settings.error_class);
          }
        }
      });
    }
  };

  global.lightBox = function(selector, options) {
    selector = selector || '[rel="lightbox"]';
    options = options || {
      cyclic: true,
      helpers: {
        title: {
          type: 'inside',
        },
      },
    };
    $(selector).fancybox(options);
  };

  global.mobileAlbumLightBox = function(selector, options) {
    selector = selector || '[rel="lightbox"]';
    options = options || {
      cyclic: true,
      helpers: {
        title: {
          type: 'outside',
        },
        overlay: {
          opacity: 0.8, // or the opacity you want
          css: {
            'background-color': '#000000',
          }, // or your preferred hex color value
        },
      },
      width: '100%',
      padding: 0,
      autoSize: false,
      autoResize: false,
      margin: 0,
      maxWidth: '100%',
      closeBtn: true,
      nextClick: true,
      beforeLoad: function() {
        this.title = $('<div>')
          .append(
            $('<div class="text-center">').append(
              $(
                '[rel="album-add"][data-id="' +
                  $(this.element).data('id') +
                  '"]'
              ).clone()
            )
          )
          .html();
        $('#fancybox-content')
          .find('img')
          .addClass('newClass');
      },
      beforeShow: function() {
        $('.fancybox-wrap').addClass('mobile-album-lightbox');
      },
    };
    $(selector).fancybox(options);

    $('body').delegate('a[rel="album-add"]', 'click', function() {
      jQuery.fancybox.close();
    });
  };

  global.stateCity = function(select1, select2, initial1, initial2) {
    initial2 = $.defined(initial2) ? initial2 : '';
    var state = $('select[name="' + select1 + '"]');
    var city = $('select[name="' + select2 + '"]');

    // Preselect options if initial value is defined
    if (
      $.defined(initial1) &&
      initial1 != '' &&
      $.defined(state.children("option[value='" + initial1 + "']")[0])
    ) {
      state.children('option[value=' + initial1 + ']')[0].selected = true;
      _loadCities(initial1, select2, initial2);
    }

    state.bind(
      'change',
      function(ev) {
        _loadCities(ev.target.value, select2, '');
      }.bind(this)
    );
  };

  global._loadCities = function(parent, element, value) {
    var select = _setSelectLoading(element);
    value = value ? value : '""';

    $.getJSON(
      '/do_ajax/autocomplete_cities',
      {
        use_key: 'id',
        state: parent,
      },
      function(data) {
        select.empty();
        $.each(data.cities, function(i, e) {
          select[0].options[select[0].options.length] = new Option(
            e.name,
            e.id
          );
        });
        var city_selected = select.children('option[value=' + value + ']')[0];
        select.trigger('options_changed');
        select.removeAttr('disabled');
        if (city_selected) {
          city_selected.selected = true;
        }
      }
    );
  };

  global._setSelectLoading = function(element) {
    var select = $('select[name="' + element + '"]')
      .empty()
      .attr('disabled', true);
    select[0].options[select[0].options.length] = new Option(
      __('Cargando...'),
      ''
    );
    return select;
  };

  global.categorySubcategory = function(select1, select2, initial1, initial2) {
    initial2 = $.defined(initial2) ? initial2 : '';
    var select = $('select[name="' + select1 + '"]');
    // Preselect options if initial value is defined
    if ($.defined(initial1) && initial1 != '') {
      select.children('option[value=' + initial1 + ']')[0].selected = true;
      _loadSubCategories(initial1, select2, initial2);
    }

    select.bind(
      'change',
      function(ev) {
        _loadSubCategories(ev.target.value, select2, '');
      }.bind(this)
    );
  };

  global._loadSubCategories = function(parent, element, value) {
    var select = _setSelectLoading(element);
    value = value ? value : '""';

    $.getJSON(
      '/do_ajax/autocomplete_subcategories',
      {
        category: parent,
      },
      function(data) {
        select.empty();
        $.each(data.subcategories, function(i, e) {
          select[0].options[select[0].options.length] = new Option(
            e.name,
            e.id
          );
        });
        select.children('option[value=' + value + ']')[0].selected = true;
        select.trigger('options_changed');
        select.removeAttr('disabled');
      }
    );
  };

  global.subcategorySubSubcategory = function(
    select1,
    select2,
    initial1,
    initial2
  ) {
    initial2 = $.defined(initial2) ? initial2 : '';
    var select = $('select[name="' + select1 + '"]');

    // Preselect options if initial value is defined
    if ($.defined(initial1) && initial1 != '') {
      //select.children("option[value="+initial1+"]")[0].selected = true;
      global._loadSubSubCategories(initial1, select2, initial2);
    }

    select.bind(
      'change',
      function(ev) {
        global._loadSubSubCategories(ev.target.value, select2, '');
      }._bind(global)
    );
  };

  global._loadSubSubCategories = function(parent, element, value) {
    var select = global._setSelectLoading(element);
    value = value ? value : '""';

    $.getJSON(
      '/do_ajax/autocomplete_subsubcategories?subcategory=' + parent,
      function(data) {
        select.empty();
        $.each(data.subsubcategories, function(i, e) {
          select[0].options[select[0].options.length] = new Option(
            e.name,
            e.id
          );
        });
        select.children('option[value=' + value + ']')[0].selected = true;
        if (select.attr('size')) {
          select.attr('size', data.subsubcategories.length);
        }

        if (select[0].options.length == 1) {
          select.empty();
          select[0].options[select[0].options.length] = new Option(
            __('Todas'),
            0
          );
          $('#subsubcategory_selector').addClass('hidden');
        } else {
          $('#subsubcategory_selector').removeClass('hidden');
          select.removeAttr('disabled');
        }
        select.trigger('options_changed');
      }
    );
  };

  global.tokenize = function(selector, url, options) {
    options = options || {};
    var default_options = {
      hintText: '',
      noResultsText: '',
      searchingText: '',
      contentType: 'text',
      prePopulateFromInput: true,
      allowNewValues: true,
    };
    var tokenize_settings = $.extend(default_options, options);
    var $input = $(selector);

    if ($input.attr('tokenized')) {
      return;
    }

    if ($.defined($input.tokenInput)) {
      try {
        var pos = $input.position();
      } catch (err) {
        // if the field is hidden position can't be triggered
        $input.focus(function() {
          tokenize(selector, url, options);
        });
        return;
      }
      $input.tokenInput(url, tokenize_settings);

      var $new_input_wrap = $input.next();
      var $new_input = $new_input_wrap.find('input');
      $new_input.addClass('form-control');
      var width = $new_input.outerWidth() + 'px';

      var $dropdown = $new_input_wrap.next().css('width', width);
      $dropdown.next().css('width', width);
      var $form = $input.parents('form');

      if ($form.hasClass('newform')) {
        $dropdown = $new_input_wrap.next().css('margin-left', pos.left);
        $dropdown.next().css('margin-left', pos.left);
      } else if ($form.hasClass('stunning')) {
        $dropdown.next().css('width', 'auto');
      }

      $(selector).attr('tokenized', true);
    } else {
      // load script and repeat call
      $.getScript('/js/jq/jquery.tokeninput.js', function() {
        tokenize(selector, url, options);
      });
    }
    return;
  };

  global.formSubmitFeedback = function(button) {
    var onClick = function() {
      if ($(this).hasClass('disabled')) {
        // this will prevent multiple form submissions by discarding the event
        return false;
      }

      var sending_message = __('Procesando...');
      $(this).addClass('disabled');

      try {
        $(this).text(sending_message);
      } catch (err) {
        // IE will throw an exception if this is called for an input type="image"
      }

      // don't modify value if the element has a name, because the value may be relevant to the form
      if (!$(this).attr('name')) {
        $(this).attr('value', sending_message);
      }

      return true;
    };

    if (typeof button === 'string') {
      $(document).on('click', button, onClick);
    } else {
      $(button).on('click', onClick);
    }
  };

  global.FormHelper = function() {
    function FormHelper(form, settings) {
      this.$form = $(form);

      this.settings = {
        position: 'right',
        offset_h: 0,
        offset_w: 0,
        class: '',
      };

      if (typeof settings === 'string') {
        this.settings.position = settings;
      } else {
        this.settings = $.extend(this.settings, settings || {});
      }

      if (!this.settings.template) {
        this.settings.template =
          '<div id="form-hint" class="popover ' +
          this.settings.position +
          '"><div class="arrow"></div>' +
          '<div class="popover-content"><p></p></div></div>';
      }

      this.buildUI();
      this.getSpacialLimits();
      this.setEvents();
    }

    $.extend(FormHelper.prototype, {
      buildUI: function() {
        this.$floater = $(this.settings.template).hide();
        $('#' + this.$floater.attr('id')).remove(); // prevent floater duplication.
        this.$floater
          .addClass(this.settings.position)
          .addClass(this.settings['class'])
          .appendTo($('body'));
      },

      setEvents: function() {
        this.$form.delegate(':input', 'focus', this.onFieldFocus.bind(this));
        this.$form.delegate(':input', 'blur', this.onFieldBlur.bind(this));
      },

      onFieldFocus: function(event) {
        var help = this.getFieldHelp(event.target);

        if (help) {
          this.$floater.find('p').html(help);
          this.setFloaterPosition(event.target);
          this.$floater.show();
        }
      },

      onFieldBlur: function() {
        this.hideFloater();
      },

      getFieldHelp: function(element) {
        return $(element)
          .nextAll('.help')
          .html();
      },

      setFloaterPosition: function(element) {
        var handlers = {
          top: this.getFloaterPositionTop,
          left: this.getFloaterPositionLeft,
          right: this.getFloaterPositionRight,
        };

        var positioning =
          $(element).attr('positioning') || this.settings.position;
        var pos = handlers[positioning].bind(this)(element);
        this.$floater.removeClass(this.settings.position).addClass(positioning);
        var margin = 6;

        if (pos.top < this.limits.top) {
          pos.top = this.limits.top + margin;
        }
        if (pos.left < this.limits.left) {
          pos.left = this.limits.left + margin;
        }
        if (pos.right > this.limits.right) {
          pos.left = this.limits.right - this.$floater.outerWidth() - margin;
        }
        this.$floater.show();
        this.$floater.offset(pos);
      },

      hideFloater: function() {
        this.$floater.hide();
      },

      getFloaterPositionTop: function(element) {
        var fieldPos = $(element).offset();
        var hintPos = {
          top:
            fieldPos.top - this.$floater.innerHeight() + this.settings.offset_h,
          left: fieldPos.left + this.settings.offset_w,
        };
        hintPos.bottom = hintPos.top + this.$floater.outerHeight();
        hintPos.right = hintPos.left + this.$floater.outerWidth();
        return hintPos;
      },

      getFloaterPositionLeft: function(element) {
        var fieldPos = $(element).offset();
        var hintPos = {
          top:
            fieldPos.top -
            this.$floater.outerHeight() / 2 +
            $(element).outerHeight() / 2 +
            this.settings.offset_h,
          left:
            fieldPos.left - this.$floater.outerWidth() + this.settings.offset_w,
        };
        hintPos.bottom = hintPos.top + this.$floater.outerHeight();
        hintPos.right = hintPos.left + this.$floater.outerWidth();
        return hintPos;
      },

      getFloaterPositionRight: function(element) {
        var fieldPos = $(element).offset();
        var hintPos = {
          top:
            fieldPos.top -
            this.$floater.outerHeight() / 2 +
            $(element).outerHeight() / 2 +
            this.settings.offset_h,
          left:
            fieldPos.left + $(element).outerWidth() + this.settings.offset_w,
        };
        hintPos.bottom = hintPos.top + this.$floater.outerHeight();
        hintPos.right = hintPos.left + this.$floater.outerWidth();
        return hintPos;
      },

      getSpacialLimits: function() {
        var container;
        var ancestors = this.$floater.parents().filter(function(i, e) {
          return $(e).css('overflow') == 'hidden';
        });

        if (ancestors.length) {
          container = $(ancestors[0]);
          this.limits = {
            top: container.offset().top,
            left: container.offset().left,
            bottom: container.offset().top + container.outerHeight(),
            right: container.offset().left + container.outerWidth(),
          };
        } else {
          container = $(window);
          this.limits = {
            top: 0,
            left: 0,
            bottom: container.height(),
            right: container.width(),
          };
        }
      },
    });

    FormHelper.prototype.hide = FormHelper.prototype.hideFloater;

    return FormHelper;
  };

  global.PhotoEditor = function PhotoEditor($container) {
    if (!$container) {
      this.$container = null;
    } else {
      this.$container = $container;
      this.$container.sortable({
        items: '.polaroid',
        helper: 'clone',
        distance: 5,
        stop: function(e, ui) {
          var $nextItem = ui.item.next('.polaroid');
          var this_id = ui.item
              .attr('id')
              .split('-')
              .pop(),
            next_id = $nextItem.length
              ? $nextItem
                  .attr('id')
                  .split('-')
                  .pop()
              : null;
          var url =
            '/do_ajax/sort_photo/' +
            this_id +
            (next_id === null ? '/last' : '/before/' + next_id);
          $.ajax({
            url: url,
          });
        },
      });
    }
    this.configure();
    this.buildUploader();
    this.setEvents();
  };

  global.PhotoEditor.prototype = {
    configure: function() {
      this.form = undefined;
      this.uploader = undefined;
      this.attachment_type = undefined;
      this.url = undefined;
    },

    getEditLinks: function() {
      return $('.polaroid .edit-link');
    },

    setEvents: function() {
      var fancybox_settings = {
        helpers: {
          title: {
            type: 'inside',
          },
        },
        type: 'ajax',
      };
      this.setUploaderEvents();
      this.getEditLinks().bind(
        'click',
        function(ev) {
          this.active_photo = $(ev.target).parents('.polaroid');
        }.bind(this)
      );
      var edit_links = this.getEditLinks();
      if (edit_links.length > 0) {
        edit_links.fancybox(fancybox_settings);
      }
    },

    setUploaderEvents: function() {
      this.uploader.bind('FileUploaded', this.onFileUploaded.bind(this));
      this.uploader.bind('UploadComplete', this.onUploadSuccess.bind(this));
    },

    setModalEvents: function() {
      $('#floating_edit_photo form')
        .unbind('submit')
        .bind('submit', this.saveImageData.bind(this));
      $('#floating_edit_photo .delete').bind(
        'click',
        this.deletePhoto.bind(this)
      );

      // not really an event, but fits well here
      new BeautyCheckbox('.click_tags input');
    },

    buildUploader: function() {
      var url = this.url || '/upload/new_image';
      if (this.attachment_type) {
        url = url + '?attachment_type=' + this.attachment_type;
      }

      $('#uploader').pluploadQueue({
        runtimes: this.getRuntimes(),
        max_file_size: Hab.config.plupload.max_file_size,
        unique_names: false,
        url: url,
        multipart: true,
        flash_swf_url:
          'http://static.habitissimo.es/components/plupload/js/Moxie.swf',
        filters: [Hab.config.plupload.filters.img],
        setup: this.setUploaderUIEvents,
      });

      this.uploader = $(this.uploader).pluploadQueue();
    },

    getRuntimes: function() {
      return 'html5,html4';
    },

    setUploaderUIEvents: function(uploader) {
      // Add some habitissimo style
      $('.plupload_start').css({
        opacity: 0.6,
      });

      uploader.bind(
        'QueueChanged',
        function(up) {
          if (up.files.length > 0) {
            $('.plupload_start').css({
              opacity: 1,
            });
          } else {
            $('.plupload_start').css({
              opacity: 0.6,
            });
          }
          //uploader.refresh();
        }.bind(this)
      );

      uploader.refresh();
    },

    saveImageData: function(e) {
      var form = $(e.target);
      var form_data = form.serializeArray();
      this.last_query = this.serialToObject(form_data);
      this.editing_photo = form
        .attr('action')
        .split('/')
        .pop();
      $.post(form.attr('action'), form_data, this.onSaveSuccess.bind(this));
      return false;
    },

    onSaveSuccess: function(data) {
      data = $.parseJSON(data);
      if (data.status == 'success') {
        $.fancybox.close();
        window.habTracker.track('edit business profile success',
          {
            "profile section" : "photos",
            "action" : "update"
          }
        );

        var $polaroid = $('#polaroid-' + this.editing_photo);

        $polaroid.fadeOut(
          'fast',
          function() {
            try {
              $('.title', $polaroid).text(
                truncateString(this.last_query.title, 38)
              );
              $('.description', $polaroid).text(
                truncateString(this.last_query.description, 85)
              );
              $polaroid.fadeIn('slow');
            } catch (e) {
              $polaroid.show();
            }
          }.bind(this)
        );
        return true;
      } else {
        alert(data.msg);
        return false;
      }
    },

    onUploadSuccess: function() {
      this.form.submit();
    },

    onFileUploaded: function(uploader, file, response) {
      response = $.parseJSON(response.response);
      this.form.append(
        nano(
          '<input type="hidden" name="photos[{id}][title]" value=""/>',
          response
        )
      );
      this.form.append(
        nano(
          '<input type="hidden" name="photos[{id}][desc]" value=""/>',
          response
        )
      );
    },

    serialToObject: function(data) {
      var obj = {};
      for (var i = 0; i < data.length; i++) {
        obj[data[i].name] = data[i].value;
      }
      return obj;
    },

    deletePhoto: function(ev) {
      ev.preventDefault();
      if (
        !confirm(
          __(
            '¿Estás seguro que quieres eliminar la foto? Esta operación es irreversible'
          )
        )
      ) {
        return;
      }

      $.getJSON($(ev.target).attr('href'), {}, this.onDeleteSuccess.bind(this));
    },

    onDeleteSuccess: function(response) {
      if (response.status == 'success') {
        $.fancybox.close();
        window.habTracker.track('edit business profile success',
          {
            "profile section" : "photos",
            "action" : "delete"
          }
        );
        this.active_photo.fadeOut();
      } else if ($.defined(response.msg)) {
        alert(response.msg);
      }
    },
  };

  global.StateCitySelector = (function() {
    function StateCitySelector(state, city, zip) {
      this.state = $(state);
      this.city = $(city);
      this.zip = $(zip);
      this.setEvents();
    }

    $.extend(StateCitySelector.prototype, {
      setEvents: function() {
        this.state.bind('change', this.updateCities.bind(this));
        this.zip.bind('change', this.selectByZip.bind(this));
      },

      updateCities: function() {
        this.city.empty().attr('disabled', true);
        this.city[0].options[0] = new Option(__('Cargando'), '');

        $.getJSON(
          '/do_ajax/autocomplete_cities',
          {
            use_key: 'id',
            state: this.state.val(),
          },
          function(data) {
            this.city.empty();
            $.each(
              data.cities,
              function(i, e) {
                this.city[0].options[this.city[0].options.length] = new Option(
                  e.name,
                  e.id
                );
              }.bind(this)
            );
            this.city.removeAttr('disabled').trigger('options_changed');
          }.bind(this)
        );
      },

      selectByZip: function() {
        var zip = this.zip.val().trim();

        if (zip !== '') {
          $.getJSON(
            '/do_ajax/autocomplete_zip/' + zip,
            {},
            function(data) {
              if (!data || !('normalized_state' in data)) {
                this.zip.trigger('not_found');
                return;
              }

              this.city.one('options_changed', function() {
                $(this).val(data.city_id);
                $(this).trigger('change');
              });

              this.state.val(data.normalized_state).trigger('change');
            }.bind(this)
          );
        }
      },
    });

    return StateCitySelector;
  })(window);

  global.CategorySubcategorySelector = (function() {
    function CategorySubcategorySelector(category, subcategory, worktype) {
      this.category = $(category);
      this.subcategory = $(subcategory);
      this.worktype = $(worktype);
      this.setEvents();
    }

    $.extend(CategorySubcategorySelector.prototype, {
      setEvents: function() {
        if (this.category.length) {
          this.category.bind('change', this.updateSubcategories.bind(this));
        }

        if (this.subcategory.length && this.worktype.length) {
          this.subcategory.bind('change', this.updateWorkTypes.bind(this));
        }
      },

      updateSubcategories: function() {
        this.subcategory.empty().attr('disabled', true);
        this.subcategory[0].options[0] = new Option(__('Cargando'), '');

        $.getJSON(
          '/do_ajax/autocomplete_subcategories',
          {
            category: this.category.val(),
          },
          function(data) {
            this.subcategory.empty();
            $.each(
              data.subcategories,
              function(i, e) {
                this.subcategory[0].options[
                  this.subcategory[0].options.length
                ] = new Option(e.name, e.id);
              }.bind(this)
            );
            this.subcategory.removeAttr('disabled').trigger('options_changed');
          }.bind(this)
        );
      },

      updateWorkTypes: function() {
        if (this.worktype.length == 0) {
          return;
        }

        this.worktype.empty().attr('disabled', true);
        this.worktype[0].options[0] = new Option(__('Cargando'), '');

        $.getJSON(
          '/do_ajax/autocomplete_subsubcategories',
          {
            subcategory: this.subcategory.val(),
          },
          function(data) {
            this.worktype.empty();
            if (data.subsubcategories.length == 1) {
              this.worktype[0].options[0] = new Option(__('Sin opciones'), '');
            } else {
              $.each(
                data.subsubcategories,
                function(i, e) {
                  this.worktype[0].options[
                    this.worktype[0].options.length
                  ] = new Option(e.name, e.id);
                }.bind(this)
              );
              this.worktype.removeAttr('disabled');
            }
            this.worktype.trigger('options_changed');
          }.bind(this)
        );
      },
    });

    return CategorySubcategorySelector;
  })();

  global.FieldCounter = (function() {
    function FieldCounter(field, options) {
      this.field = $(field);
      this.settings = $.extend(
        {
          count: 'down', // up/down
          goal: '140',
          allow_negative: false,
          msg: __(
            '<span class="text-bold">{count}</span> caracteres para terminar'
          ),
          template:
            '<span id="{field_id}_counter" class="field-counter"></span>',
          show_msg: true,
          hide_on_empty: true,
        },
        options || {}
      );
      this.buildCounterMsg();
      this.setEvents();
      this.refresh();
    }

    $.extend(FieldCounter.prototype, {
      buildCounterMsg: function() {
        this.field.after(
          nano(this.settings.template, {
            field_id: this.field.attr('id'),
          })
        );
        this.message_element = $('#' + this.field.attr('id') + '_counter');
      },

      setEvents: function() {
        this.field.bind('keyup', this.refresh.bind(this));
        this.field.bind('refresh', this.onRefresh.bind(this));
      },

      getStrVars: function() {
        var vars = {};
        vars['goal'] = this.settings.goal;
        vars['count'] = this.length;
        if (this.settings.count == 'down') {
          vars['count'] = this.settings.goal - this.length;
        }

        if (!this.settings.allow_negative && vars['count'] < 0) {
          vars['count'] = 0;
        }
        return vars;
      },

      refresh: function() {
        this.field.trigger('refresh');
      },

      onRefresh: function() {
        this.length = this.field.val().length;
        this.settings.show_msg = this.length < this.settings.goal;

        if (this.settings.hide_on_empty && this.length == 0) {
          this.settings.show_msg = false;
        }

        if (this.settings.show_msg) {
          this.message_element.html(nano(this.settings.msg, this.getStrVars()));
        } else {
          this.message_element.empty();
        }
      },
    });

    return FieldCounter;
  })();

  global.Mask = {
    phone: function(coll) {
      if (window.mask_phone) {
        return $(coll).mask(window.mask_phone, {
          translation: {
            '8': {
              pattern: /[1-9]/,
            },
            '?': {
              pattern: /9/,
              optional: true,
            },
            r: {
              pattern: /[9]/,
              fallback: '9',
            },
          },
        });
      }

      return undefined;
    },

    mobile: function(coll) {
      if (window.mask_mobile) {
        return $(coll).mask(window.mask_mobile, {
          translation: {
            '8': {
              pattern: /[1-9]/,
            },
            '?': {
              pattern: /9/,
              optional: true,
            },
            r: {
              pattern: /[9]/,
              fallback: '9',
            },
          },
        });
      }
      return undefined;
    },

    zip: function(coll) {
      if (window.mask_zip) {
        return $(coll).mask(window.mask_zip);
      }
      return undefined;
    },

    iban: function(coll) {
      if (window.mask_iban) {
        return $(coll).mask(window.mask_iban);
      }
      return undefined;
    },

    creditCard: function(coll) {
      $(coll).mask('9999 9999 9999 9999');
    },

    year: function(coll) {
      $(coll).mask('9999');
    },

    mobileCode: function(coll, length) {
      $(coll).mask(Array(length + 1).join('9'));
    },
  };

  global.fancyboxProfiles = {
    gallery: {
      cyclic: true,
      helpers: {
        title: {
          type: 'inside',
        },
      },
    },
    thickbox: {
      helpers: {
        title: {
          type: 'over',
        },
      },
    },
    form: {
      helpers: {
        title: null,
      },
    },
    modalForm: {
      helpers: {
        title: null,
      },
      modal: true,
    },
  };

  global.openNewTab = function(href) {
    var redirectWindow = window.open(href, '_blank');
    redirectWindow.location;
  };

  global.go = function(href) {
    window.location = href;
  };

  global.base64_decode = function(data) {
    var excludes =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
    var lo;
    var surrogate2;
    var o3;
    var o1;
    var o2;
    var k;
    var h4;
    var bits;
    var i = 0;
    var ac = 0;
    var dec = '';
    var tmp_arr = [];
    if (!data) {
      return data;
    }
    data += '';
    do {
      o1 = excludes.indexOf(data.charAt(i++));
      o2 = excludes.indexOf(data.charAt(i++));
      k = excludes.indexOf(data.charAt(i++));
      h4 = excludes.indexOf(data.charAt(i++));
      bits = (o1 << 18) | (o2 << 12) | (k << 6) | h4;
      lo = (bits >> 16) & 255;
      surrogate2 = (bits >> 8) & 255;
      o3 = bits & 255;
      if (k == 64) {
        tmp_arr[ac++] = String.fromCharCode(lo);
      } else {
        if (h4 == 64) {
          tmp_arr[ac++] = String.fromCharCode(lo, surrogate2);
        } else {
          tmp_arr[ac++] = String.fromCharCode(lo, surrogate2, o3);
        }
      }
    } while (i < data.length);
    dec = tmp_arr.join('');
    return dec;
  };

  global.removeParam = function(key, url) {
    var newUrl = url.split('?')[0];
    var param;
    var params = [];
    var queryString = url.indexOf('?') !== -1 ? url.split('?')[1] : '';
    if (queryString !== '') {
      params = queryString.split('&');
      for (var i = params.length - 1; i >= 0; i -= 1) {
        param = params[i].split('=')[0];
        if (param === key) {
          params.splice(i, 1);
        }
      }
      newUrl += '?' + params.join('&');
    }
    return newUrl;
  };

  global.currencyFormat = function(value, show_decimals) {
    if (isNaN(value)) {
      return '';
    }

    if (show_decimals === undefined) {
      show_decimals = true;
    }

    var numeric_value = Number(value),
      n_decimals = show_decimals ? 2 : 0,
      format = window.currency_format,
      nano_template = format.replace('%1%', '{value}'),
      str_value;

    str_value = numeric_value.toFixed(n_decimals);
    str_value = localeFormatNumber(str_value);

    return nano(nano_template, {
      value: str_value,
    });
  };

  // Using this custom method instead of Number.toLocaleString() in order to be consistent with server side locale.
  global.localeFormatNumber = function(value) {
    if (isNaN(value)) {
      return '';
    }

    var str_value = value.toString(),
      decimal_point = window.decimal_separator,
      thousands_sep = window.thousands_separator;

    str_value = str_value.replace('.', decimal_point); // Original decimal point;
    str_value = str_value.replace(/\B(?=(\d{3})+(?!\d))/g, thousands_sep); // Thousand separator every 3 digits.

    return str_value;
  };

  global.trackAnalyticsEvent = function(category, action, label) {
    if (typeof dataLayer !== 'undefined') {
      dataLayer.push({
        category: category,
        action: action,
        label: label,
        event: 'eventga',
      });
    }
  };

  global.hideContentByInputRadios = function loopOverRadios($radios) {
    var radioValueChecked = '';
    $radios.each(function() {
      var radioValue = $(this).val();
      var isChecked = $(this).prop('checked');
      if (isChecked) {
        radioValueChecked = radioValue;
      } else {
        $('[name~="' + radioValue + '"]').hide();
      }
    });

    $('[name~="' + radioValueChecked + '"]')
      .show()
      .addClass('fadeIn animated')
      .removeClass('hidden');
  };

  global.isMobile = function() {
    return $(window).width() < 768;
  };
  
  global.dataHrefClick = function(){
    var links = document.querySelectorAll('[data-href-click]');
    links.forEach((link) => {
      link.addEventListener('click', function (event) {
        event.preventDefault();
        const pageLink = this.getAttribute('data-href-click');
        if (this.hasAttribute('data-href-blank')) {
          window.open(pageLink, '_blank');
        } else {
          window.location.href = pageLink;
        }
      });
    });
  }

  dataHrefClick();

  global.obfuscateParamInPagination = function(callback, param_to_filter = '?'){
    var uls = document.querySelectorAll('ul.pagination');

    Array.prototype.forEach.call(uls, function (elem){
      var lis = elem.children;
      Array.prototype.forEach.call(lis, function (li){
        var children = li.children
        if(children[0].tagName == 'A'){
          var link = children[0].getAttribute('href');
          if(param_to_filter === '?'){
            var filtered_link = link.split(param_to_filter);
            children[0].setAttribute('href', filtered_link[0]);
          }else{
            children[0].setAttribute('href', param_to_filter);
          }
          children[0].setAttribute('data-href-click', link);
        }
      })
    });
    callback();
  }

})(window);
