import {
  defaultConfig,
  LAZY_IMAGE_SELECTOR,
  LAZY_IMAGE_LOADED_CLASS,
} from './constants';
import {
  isABot,
  updateBGImage,
  hasBGAttribute,
  hasIntersectionObserver,
} from './lazy-utils';

const revalidateDOM = function() {
  const $images = $(LAZY_IMAGE_SELECTOR);
  let observer;

  const onIntersection = function(entries) {
    entries.forEach(function(entry) {
      // Are we in viewport?
      if (entry.intersectionRatio > 0) {
        // Stop watching and load the image
        observer.unobserve(entry.target);

        loadImage($(entry.target));
      }
    });
  };

  if (isABot() || !hasIntersectionObserver()) {
    $images.each(function() {
      loadImage($(this));
    });
  } else {
    observer = new IntersectionObserver(onIntersection, defaultConfig);

    // The observer for the images on the page
    $images.each(function() {
      observer.observe($(this).get(0));
    });
  }
};

const loadImage = function($ele) {
  $ele.removeClass(LAZY_IMAGE_SELECTOR).addClass(LAZY_IMAGE_LOADED_CLASS);

  if (hasBGAttribute($ele)) {
    updateBGImage($ele);
  } else {
    $ele.attr('src', $ele.attr('data-src'));
  }
};

revalidateDOM();
$(document).on('lazyImagesReload', revalidateDOM);
