(function(w, $) {
  $.fn.tagSearcher = function() {
    var ENDPOINT = '/p/api/autocomplete/tag';
    var INIT_ENDPOINT = '/p/api/tags';
    var WILDCARD = '%QUERY';
    var BASE_OPTIONS = {
      extraOption: {},
      alwaysSelectOne: true,
      delay: 150,
      hint: true,
      minLength: 0,
      autoselect: true,
      highlight: true,
    };

    var tagConfig = $.extend({}, BASE_OPTIONS, {
      source: ENDPOINT,
      input: '[name=category-name]',
      id_key: 'id',
      prepare_request: function(query, request) {
        if (query.length === 0) {
          request.url = INIT_ENDPOINT;
        } else {
          request.url = request.url.replace(
            WILDCARD,
            encodeURIComponent(query)
          );
        }

        return request;
      },
      suggestion_template: function(value) {
        var template = $('<span>', { text: value.name });
        if (value.parent_name) {
          var gray_text = __('en') + ' ' + value.parent_name;
          template.append(
            ' ',
            $('<small>', { text: gray_text }).addClass('text--gray')
          );
        }

        return template;
      },
      templates: {
        notFound: function(searched) {
          if (!searched.query || searched.query.length < 2) {
            return;
          }

          var template = $('<span>', {
            text: __('No se han encontrado resultados'),
          });
          template.addClass('tt-suggestion tt-not-found');

          return template;
        },
      },
    });

    typeaheadAutocomplete($(this), tagConfig);
  };
})(window, jQuery);
