$(function () {
  $('.wc-table thead th').mouseenter(function(){
    let $this = $(this);

    if(this.offsetWidth < this.scrollWidth){
      $this.attr('data-toggle', 'table-popover');

      $this.popover({
        trigger: 'hover focus',
        placement: 'bottom',
        container: 'body'
      });
    }
  })
});
