$(function () {
  const $table = $('.wc-table');
  const $tableWrapper = $('.table-responsive');

  let tableWidth = $table.outerWidth();
  let tableWrapperWidth = $tableWrapper.outerWidth();

  scrollRight($tableWrapper, tableWidth, tableWrapperWidth);

  $( window ).resize(function() {
    tableWidth = $table.outerWidth();
    tableWrapperWidth = $tableWrapper.outerWidth();
    scrollRight($tableWrapper, tableWidth, tableWrapperWidth);
  });

});

function scrollRight($elementToScroll, tableWidth, tableWrapperWidth) {
  if (tableWrapperWidth < tableWidth){
    $elementToScroll.scrollLeft($elementToScroll.scrollLeft() + 1);
  }
}
