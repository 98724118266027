(function($) {
  'use strict';
  var trackPageView = function(url) {
    var cleanurl = url.replace(/(http[s]?:\/\/[^/]+\/)?/, '/');
    if (typeof ga !== 'undefined' && typeof ga == 'function') {
      ga('send', 'pageview', cleanurl);
    }
  };

  var range = function(start, stop, step) {
    var a = [start],
      b = start;
    while (b < stop) {
      a.push((b += step || 1));
    }
    return a;
  };

  var instance = null;

  $.slideshow = function(selector, options, callback) {
    if (!instance) {
      instance = new $.slideshowClass(selector, options, callback);
    }
    return instance;
  };

  $.slideshow.defaults = {
    preload: 5,
    onLastReached: undefined,
  };

  $.slideshowClass = function(selector, options) {
    this.selector = selector;
    this.opts = $.extend({}, $.slideshow.defaults, options);
    this.state = this._getCleanState();
    this._buildOverlay();
    this._bindEvents();
    this._debug('slideshow instance created');
  };

  $.slideshowClass.prototype = {
    /*
     * Open a link in a popup and show a slideshow navigation if
     * it has sibling links.
     */
    open: function(element) {
      var el = $(element);

      if (this.state.duringAjax !== false) {
        return this;
      }

      if (el.length === 0) {
        this._debug("I don't know how to open this", element);
        return this;
      }

      if (!el.attr('rel')) {
        console.error(
          'Opened element has no rel attribute, this is unsupported'
        );
      }

      if (this.getContext() != el.attr('rel')) {
        this._newContext(el);
      }

      var href = el.attr('href');

      this._onOpen(href);

      var data = {};
      var instance = this;
      this.state.duringAjax = href;

      if (this.state.stack.length > 1) {
        data['hideThumbs'] = 1;
      }

      $.ajax({
        url: el.attr('href'),
        data: data,
        type: 'GET',
        cache: true,
        xhrFields: { withCredentials: true },
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
        success: function() {
          instance._ajaxSuccess.apply(instance, arguments);
        },
        error: function() {
          document.location.href = href;
        },
        complete: function() {
          instance._ajaxComplete.apply(instance, arguments);
        },
      });

      return this;
    },

    /*
     * Closes the slideshow
     */
    close: function() {
      if (this.state.open === true) {
        $('body').css('overflow', this.state.bodyOverflow);
        this.state = this._getCleanState();
        this._hideBack();
        this.overlay.find('.overlay-content').empty();
        this.overlay.css('display', 'none');
        this._restoreHistory();
      }
      return this;
    },

    /*
     * Next slide for current context
     */
    next: function() {
      this.open(this._getNextElement());
      return this;
    },

    /*
     * Previous slide for current context
     */
    prev: function() {
      this.open(this._getPrevElement());
      return this;
    },

    /*
     * Go to previous context
     */
    back: function() {
      var context = this.getContext();

      // remove current context
      delete this.state.stackPages[context];
      this.state.stack.pop();

      // fetch last page from previous context
      var pages = this.state.stackPages[this.getContext()];
      this.open(pages[this.state.stackLast[context]]);

      // remove remaining context data
      delete this.state.stackLast[context];

      if (this.state.stack.length < 2) {
        this._hideBack();
      }

      return this;
    },

    /*
     * Re-fetches all available slides for current context
     */
    reload: function() {
      var instance = this;
      var rel = this.getContext();

      $(this.selector)
        .filter('[rel="' + rel + '"]')
        .each(function() {
          var href = $(this).attr('href');
          instance.state.stackPages[rel][href] = {
            preload: $(this).data('preload'),
            rel: $(this).attr('rel'),
            href: $(this).attr('href'),
          };
        });

      if (this.state.open) {
        this._toggleNavigationMarks();
        this._preload();
      }

      return this;
    },

    /*
     * Returns the current context you are navigating
     */
    getContext: function() {
      return this.state.stack[this.state.stack.length - 1];
    },

    _newContext: function(element) {
      var ctx = element.attr('rel');

      if (this.state.stack.length >= 1) {
        var currentImg = this.overlay.find('img.subject');
        this._showBack(currentImg.attr('src'));
      }

      this.state.stack.push(ctx);
      this.state.stackLast[ctx] = this.state.currPage;
      this.state.stackPages[ctx] = [];
      this.reload();
    },

    _onOpen: function(href) {
      if (typeof this.opts.onOpen == 'function') {
        var pages = this.state.stackPages[this.getContext()];
        var keys = Object.keys(pages);
        var index = keys.indexOf(href);
        this.opts.onOpen.apply(this, [index, keys.length]);
      }
    },

    _onLastReached: function() {
      if (typeof this.opts.onLastReached == 'function') {
        return this.opts.onLastReached.apply(this);
      }
      return true;
    },

    _getNextElement: function() {
      var next = this._getElementByOffset(1);
      if (typeof next == 'undefined') {
        this._onLastReached();
      }
      return next;
    },

    _getPrevElement: function() {
      return this._getElementByOffset(-1);
    },

    _getElementByOffset: function(offset) {
      var pages = this.state.stackPages[this.getContext()];
      var keys = Object.keys(pages);
      var idx = keys.indexOf(this.state.currPage);
      var target = keys[idx + offset];
      return pages[target];
    },

    _ajaxSuccess: function(data) {
      this.state.open = true;
      this.state.currPage = this.state.duringAjax;
      this.state.duringAjax = false;

      this.overlay.css('display', 'block');
      this.overlay.find('.overlay-content').html(data);
      if (this.state.stack.length > 1) {
        this.overlay.find('.overlay-back').css('display', 'block');
      }
      this._updateHistory();

      if (this.state.bodyOverflow == false) {
        this.state.bodyOverflow = $('body').css('overflow');
        $('body').css('overflow', 'hidden');
      }

      this._toggleNavigationMarks();
      this._preload();
    },

    _toggleNavigationMarks: function() {
      this.overlay
        .find('.navigation.prev')
        .toggleClass('visible', typeof this._getPrevElement() != 'undefined');
      this.overlay
        .find('.navigation.next')
        .toggleClass('visible', typeof this._getNextElement() != 'undefined');
      this.overlay
        .find('.overlay-back')
        .toggleClass('highlight', typeof this._getNextElement() == 'undefined');
    },

    _preload: function() {
      var instance = this;
      var _range = range(this.opts.preload * -1, this.opts.preload);

      _range.forEach(function(i) {
        var element = instance._getElementByOffset(i);
        if (typeof element !== 'undefined' && element.preload) {
          instance._preloadImg(element.preload);
        }
      });
    },

    _preloadImg: function(url) {
      if (typeof this.state.cache[url] == 'undefined') {
        var img = new Image();
        img.src = url;
        this.state.cache[url] = true;
        //this._debug('preloading img', url);
      }
    },

    _ajaxComplete: function() {
      this.state.duringAjax = false;
      this._trigger('pageLoaded');
    },

    _bindEvents: function() {
      var instance = this;
      $(document).on('keyup', function(event) {
        return instance._onKeyUp(event);
      });
      $(window).on('popstate', this._onPopState);

      new LazyHammer(document).then(function(hammerTime) {
        hammerTime.get('swipe').set({ direction: Hammer.DIRECTION_HORIZONTAL });
        hammerTime.on('swipeleft swiperight', function(ev) {
          return instance._onSwipe(ev.type);
        });
      });

      $('body').delegate(this.selector, 'click', function(event) {
        return instance._onClick(event);
      });
      this._debug('events set');
    },

    _onClick: function(event) {
      this.open(event.currentTarget);
      return false;
    },

    _onSwipe: function(swipe_type) {
      if (swipe_type == 'swipeleft') {
        this.next();
        return false;
      } else if (swipe_type == 'swiperight') {
        this.prev();
        return false;
      }
      return true;
    },

    _onKeyUp: function(event) {
      if (!this.state.open) {
        return true;
      }

      if (event.which === 37) {
        this.prev();
        return false;
      } else if (event.which === 39) {
        this.next();
        return false;
      } else if (event.which === 27) {
        this.close();
        return false;
      }

      return true;
    },

    _onPopState: function(event) {
      var state = event.originalEvent.state;
      var instance = $.slideshow();

      if (state && state.open) {
        instance.state = state;
        var page =
          instance.state.stackPages[instance.getContext()][
            instance.state.currPage
          ];
        instance.open(page);
      } else {
        instance.close();
      }
    },

    _buildOverlay: function() {
      var instance = this;

      $('body').append(this._getOverlayHtml());

      this.overlay = $('#photo-overlay').css({ display: 'none' });

      this.overlay.delegate('.navigation.prev', 'click', function() {
        instance.prev();
        return false;
      });
      this.overlay.delegate('.navigation.next', 'click', function() {
        instance.next();
        return false;
      });
      this.overlay.delegate('.close', 'click', function() {
        instance.close();
        return false;
      });
      this.overlay.delegate('.close-xs', 'click', function() {
        instance.close();
        return false;
      });
      this.overlay.delegate('.overlay-back', 'click', function() {
        instance.back();
        return false;
      });
      this.overlay.delegate('.navigation-area', 'mouseenter', function() {
        instance.overlay.find('.overlay-back').addClass('visible');
      });
      this.overlay.delegate('.navigation-area', 'mouseleave', function() {
        setTimeout(function() {
          instance.overlay.find('.overlay-back').removeClass('visible');
        }, 1000);
      });
      this.overlay.on('click', function(event) {
        if (!$(event.target).is('#page-photo *, #page-photo')) {
          instance.close();
        }
      });
    },

    _getOverlayHtml: function() {
      return (
        ' \
            <div id="photo-overlay"> \
                <div class="overlay-back noclose"> \
                    <span class="back-img"></span>\
                    <span class="back-img"></span>\
                    <img src="" alt="' +
        __('volver') +
        '">\
                    <span class="title">' +
        __('Volver') +
        '</span> \
                </div> \
                <div class="overlay-content"></div> \
            </div>'
      );
    },

    _pushStateSupported: function() {
      return !!(history && history.pushState);
    },

    _updateHistory: function() {
      trackPageView(this.state.currPage);

      if (!this._pushStateSupported()) {
        return;
      }

      if (history.state && history.state.open == true) {
        this._debug('updating history, replaceState');
        try {
          history.replaceState(this.state, '', this.state.currPage);
        } catch (e) {}
      } else {
        this._debug('updating history, pushState');
        try {
          history.pushState(this.state, '', this.state.currPage);
        } catch (e) {}
      }
    },

    _getThumbnailUrl: function(url) {
      return url.replace(
        /\/(medium|big|hd|original|small|thumbnail|mini_square)\//,
        '/square/'
      );
    },

    _debug: function() {
      if (!this.opts.debug) {
        return;
      }

      if (typeof console !== 'undefined') {
        var args = Array.prototype.slice.call(arguments);
        args.push(this.state);
        /* eslint no-console: "off" */
        console.log.apply(console, args);
      }
    },

    _trigger: function(eventName) {
      this._debug('trigger event', eventName);
      $(window).trigger('slideshow.' + eventName);
      $(this).trigger(eventName);
    },

    _getCleanState: function() {
      return {
        slideShow: true,
        open: false,
        duringAjax: false,
        currPage: undefined,
        rootPage: window.location.href,
        bodyOverflow: false,
        stack: [],
        stackPages: {},
        stackLast: {},
        cache: {},
      };
    },

    _hideBack: function() {
      this.overlay.find('.overlay-back').hide();
    },

    _showBack: function(src) {
      var back = this.overlay.find('.overlay-back');
      if (src) {
        back.find('img').attr('src', this._getThumbnailUrl(src));
      }
      back.show();
    },

    _restoreHistory: function() {
      if (this._pushStateSupported()) {
        if (history.state && history.state.slideShow === true) {
          setTimeout(function() {
            history.back();
          });
        }
      }
    },
  };
})(jQuery);
