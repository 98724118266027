$(function () {
  var $overlay = $('#overlay-cta');
  if (typeof $.cookie('overlay-cta') === 'undefined') {
    $overlay.find('#close-overlay').on('click', function () {
      $overlay.remove();

      $.cookie('overlay-cta', 1, {
        expires: 1,
        path: '/',
        domain: window.cookie_domain,
        secure: true,
      });
    });

    setTimeout(function () {
      $overlay.removeClass('fade');
    }, 2000);
  } else {
    $overlay.remove();
  }
});
