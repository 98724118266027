/**
 * This file should only contains global utilities functions
 *
 * All functions could be accessed with Utilities.<FN> (see Webpack config)
 */
import { modalBus } from './Modal/javascript/modalBus';
import StepsManager from './Modal/javascript/StepsManager';
import { createNoticeInstance } from './Notices/javascript/notices-utils';

// deprecated Use showNoticeWithoutDispatcher instead
export function showNoticeByName(noticeName) {
  $('[data-notice-name="' + noticeName + '"]').trigger('showNotice');
}

export function showNoticeWithoutDispatcher(opts) {
  return createNoticeInstance(null, opts).then(function(noty) {
    noty.show();

    return noty;
  });
}

export function showModalByDataAction(modalName, onShow) {
  setTimeout(function () {
    modalBus.trigger('callModal', modalName);
    if (typeof onShow === 'function') {
      $(document).on('hidden.bs.modal', function handleClose() {
        $(document).off('show.bs.modal', onShow);
        $(document).off('hidden.bs.modal', handleClose);
      });

      $(document).on('show.bs.modal', onShow);
    }
  }, 0);
}

export function createStepManager(steps, animationTime) {
  return new StepsManager(steps, animationTime);
}

export function EventCallbacksPair(eventType, callback) {
  this.eventType = eventType;
  this.callbacks = [callback];
}

export var EventBus = {};

(function() {
  var subscribers = [];

  EventBus.subscribe = function(eventName, cb) {
    subscribers.push({
      eventName: eventName,
      cb: cb,
    });
  };

  EventBus.emit = function(eventName, data) {
    var callbacks = subscribers.filter(function(subcriber) {
      return subcriber.eventName === eventName;
    });
    callbacks.forEach(function(subcriber) {
      subcriber.cb(data);
    });
  };

})();

