jQuery(function($) {
  $(document.body).on(
    'click',
    '.range-input .range-labels a[data-value]',
    function() {
      var $this = $(this);
      var $input = $this.closest('.range-input').find('input[type=range]');
      $input.val($this.data('value')).trigger('change');
    }
  );
});
