jQuery(document).ready(function ($) {

    $('.wc-back-to-top').on('click', function(ev) {
        scrollToAnimate('body');
        ev.preventDefault();
    });

    window.addEventListener("scroll", function () {
        let y = window.scrollY;
        if (y > 800) {
            $('.wc-back-to-top').removeClass('wc-back-to-top--default').addClass('wc-back-to-top--show');
        } else {
            $('.wc-back-to-top').addClass('wc-back-to-top--default').removeClass('wc-back-to-top--show');
        }
    });
});