(function(window, $) {
  'use strict';

  $.fn.trackErrors = function(options) {
    if (
      typeof dataLayer == 'undefined' ||
      typeof dataLayer.push == 'undefined'
    ) {
      return this;
    }

    var opts = $.extend(
      {
        trackFunction: 'dataLayer.push',
        eventName: 'eventga',
        trackCategory: 'FormError',
        trackAttribute: 'data-trackid',
        errorSelector: '.form_error[id]',
        debug: false,
      },
      options
    );

    var debug = function() {
      /* eslint no-console: "off" */
      if (opts.debug) {
        console.log(arguments);
      }
    };

    // Retrieve the track function and check if its defined
    var trackFunc = window;
    var callElements = opts.trackFunction.split('.');
    for (var i = 0; i < callElements.length; i++) {
      var element = callElements[i];

      if (typeof trackFunc[element] == 'undefined') {
        debug('TrackFunction is not defined. Aborting');
        return this;
      } else if (typeof trackFunc[element] == 'function') {
        trackFunc = trackFunc[element].bind(trackFunc);
      } else {
        trackFunc = trackFunc[element];
      }
    }

    var action = $(this).attr(opts.trackAttribute);
    if (!action) {
      debug(
        'The attribute ' +
          opts.trackAttribute +
          ' was not found on the object given. Aborting.'
      );
      return this;
    }

    this.find(opts.errorSelector).each(function() {
      trackFunc({
        category: opts.trackCategory,
        action: 'FormError',
        label: $(this).data('for') || this.id,
        event: opts.eventName,
      });

      debug(opts.trackCategory, action, this.id, opts.eventName);
    });

    return this;
  };
})(window, jQuery);
