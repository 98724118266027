export const defaults = {
  distance: 100,
  loaderClass: 'moebius-loader',
  loaderContent:
    '<hr/><span class="moebius-spinner"><i class="icon-spinner-o icon-spin"></i></span>',
  endClass: 'moebius-end',
  endContent: 'You have reached the last page',
  contentSelector: undefined,
  useGC: true,
  pagerSelector: '.pagination',
  pagerNext: '.pagination a[rel="next"]',
  pagerprev: '.pagination a[rel="prev"]',
  debug: false,
  contentElements: '.gallery-container',
  history: window.history,
};
