$(function () {

  const $tableWrapper = $('.table-responsive');

  $tableWrapper.scroll(function() {
    toggleShadows($(this));
  });

  $(window).resize(function () {
    toggleShadows($(this));
  });

  function toggleShadows($table) {
    const scrollRight = $table[0].scrollWidth - $table[0].clientWidth;
    const canScrollToLeft = () => $table.scrollLeft() !== 0;
    const canScrollToRight = () => $table.scrollLeft() !== scrollRight;

    $table.prev('.table-container--shadow--left').toggleClass('hidden', !canScrollToLeft());
    $table.next('.table-container--shadow--right').toggleClass('hidden', !canScrollToRight());
  }
});
