/**
 * Access to element attribute
 * @param {*}
 * @param {*} attr
 */
export const getAttributeFromElement = ($elem, attr) => $elem.attr(attr);

/**
 * Get and validate if an attribute is active
 * @param {*}
 * @param {*} attr
 */
export const isAttributeActive = ($elem, attr) => {
  return getAttributeFromElement($elem, attr) === 'true';
};
