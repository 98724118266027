jQuery(function($) {
  $('[data-loadmore="true"]').on('click', function() {
    var $this = $(this);
    var containerName = $(this).data('container');
    var $container = $('#' + containerName);
    var $hiddenElements = $container.find('.hidden-element');
    var mainNavbarHeight = $('#main-navbar').height();

    $hiddenElements.removeClass('hidden-element hidden');
    $('html, body').animate({
      scrollTop: $hiddenElements.first().offset().top - mainNavbarHeight
    }, 700);

    $this.parent().remove();
    $this.remove();
  });
});
