jQuery(function($) {
  var $navbar = $('#main-navbar');
  var $search = $navbar.find('.navbar-search');

  $navbar.find('form.search').on('submit', function(ev) {
    var $q = $(this).find('[name=q]');

    if ($q.val().trim() === '') {
      ev.preventDefault();
      $q.focus();
    }
  });

  $search.find('.search-toggle').on('click', function() {
    var $this = $(this);
    var $search = $this.closest('.navbar-search');

    var $prev = $navbar.find('.navbar-header');
    var left = $prev.outerWidth() + $prev.position()['left'];
    var width = $this.position()['left'] + $this.outerWidth() - left;

    $search.css({
      left: left,
      width: width,
    });

    $navbar.find('#main-menu').css('visibility', 'hidden');

    $search.addClass('active');
    $search.find('[name=q]').focus();
    $(window).one('resize', closeSearchForm);
  });

  $search.find('button.close').on('click', function(ev) {
    closeSearchForm();
    ev.preventDefault();
  });

  $search.find('[name=q]').on('keyup', function(ev) {
    if (ev.which == 27) {
      closeSearchForm();
    }
  });

  function closeSearchForm() {
    $search.removeClass('active');
    $navbar.find('#main-menu').css('visibility', 'visible');

    $search.css({
      left: '',
      width: '',
    });
  }
});
