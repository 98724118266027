// Homeowners directory Amplitude event handlers

$(document).ready(function() {
  $('[data-amplitude="click direct service request"]:not([data-type="wc-cta-modal"])').click(function() {
    habTracker.track(
      $(this).data('amplitude'),
      $(this).data('amplitude-properties')
    );
  });
});
