(function(w, $) {
  'use strict';

  w.Deeplinker = {
    generateIframe: function() {
      if ($('#loader').length === 0) {
        $('<iframe>', {
          id: 'loader',
          style: 'display:none',
          height: 0,
          width: 0,
        }).appendTo('body');
      }
    },

    deeplink: function($deeplinkUrl, $fallbackUrl) {
      this.generateIframe();
      var IS_IPAD = navigator.userAgent.match(/iPad/i);
      var IS_IPHONE =
        !IS_IPAD &&
        (navigator.userAgent.match(/iPhone/i) ||
          navigator.userAgent.match(/iPod/i));
      var IS_IOS = IS_IPAD || IS_IPHONE;
      var IS_ANDROID = !IS_IOS && navigator.userAgent.match(/android/i);
      var IS_CHROME = navigator.userAgent.match(/chrome/i);

      if (IS_ANDROID && IS_CHROME) {
        window.location.href = $deeplinkUrl;
      } else if (IS_IOS || (IS_ANDROID && !IS_CHROME)) {
        $('iframe#loader').attr('src', $deeplinkUrl);
        window.setTimeout(function() {
          window.location.href = $fallbackUrl;
        }, 1000);
      } else {
        window.location.href = $fallbackUrl;
      }
    },
  };
})(window, jQuery);
